import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { DataService } from 'src/app/shared/services/data.service';
import { InteractionsService } from 'src/app/shared/services/interactions.service';

@Component({
	selector: 'app-notifiction',
	templateUrl: './notifiction.component.html',
	styleUrl: './notifiction.component.scss',
})
export class NotifictionComponent implements OnInit {
	notificationCount: number = 0;
	items: ItemModel[] = [];
	visible: boolean = false;
	selectedNotification: any = {};
	@ViewChild('dialog') ejDialog: DialogComponent | undefined;
	substitutionRequest:any = [];

	public constructor(
		public readonly dataService: DataService,
		public readonly interactionsService: InteractionsService,
	) {}

	ngOnInit() {
		this.dataService.abscencesService.updated$.subscribe(()=>{
			this.substitutionRequest = this.dataService.abscencesCacheService.substitutionRequest;
			this.notificationCount = this.dataService.abscencesCacheService.substitutionRequest.length;
			const sortedData = this.dataService.abscencesCacheService.substitutionRequest.sort((a:any, b:any) => new Date(a.from).getTime() - new Date(b.from).getTime());
			this.items = [];
			for(let request of sortedData){
				this.items.push({
					id: request.id,
					text: ` Sie wurden als Ersatz für ${request?.employee?.firstname} ${request?.employee?.lastname} hinzugefügt.`
				});
			}
		})
	}

	public select(args: MenuEventArgs) {
		const request = this.substitutionRequest.find((request:any) => request.id == args.item.id);
		this.selectedNotification = request;
		this.ejDialog!.show();
	}

	confirmRequest() {
		this.interactionsService.abscencesService.updateSubstitutionRequest({...this.selectedNotification,substitutionStatus:'accepted'});
		this.ejDialog!.hide();
	}

	rejectRequest() {
		this.interactionsService.abscencesService.updateSubstitutionRequest({...this.selectedNotification,substitutionStatus:'rejected'});
		this.ejDialog!.hide();
	}

	getToDate(date){
		if(date){
			const currentDate = new Date(date);
			return currentDate.setDate(currentDate.getDate() - 1)
		}else{
			return
		}
	}
}
