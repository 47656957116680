<div class="App overlay">
  <div class="AbsenceDialog">
    <div class="heading">
      <h2><strong>Abwesenheit beantragen</strong></h2>
    </div>
    <p>Ich habe mich mit meinem Vertreter abgestimmt,
      der meine Arbeit in der Zeit übernimmt?</p>

    <div class="fullRow">

      <button ejs-button [cssClass]="'e-'+''" [isPrimary]="+'0' == 1"
              (click)="close()">
        Abbrechen
      </button>

      <button ejs-button [cssClass]="'e-'+''" [isPrimary]="+'1' == 1" (click)="onSend()">
        Abschicken
      </button>

    </div>

  </div>
</div>
