import { NgModule } from '@angular/core';
import { GermanNumberFormatPipe } from './pipes/german-number-format.pipe';

@NgModule({
  imports: [],
  declarations: [
		GermanNumberFormatPipe
  ],
  providers: [],
	exports: [
		GermanNumberFormatPipe
	]
})
export class SharedModule {
}
