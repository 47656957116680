import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Abscence } from '../models/abscence.model';
import { AbscencesHttpRequestService } from './abscences-http-request.service';
import { HttpRequest } from '../../../engine/models/http-request.model';
import { EngineService } from '../../../engine/services/engine.service';
import { AbscencesCacheService } from './abscences-cache.service';

@Injectable({
  providedIn: 'root'
})
export class AbscencesService {
  private readonly updatedSubject: Subject<void> = new Subject<void>();
  public readonly updated$: Observable<void> = this.updatedSubject.asObservable();

  private  abscencesSubject = new BehaviorSubject<Abscence[]>([]);
  public abscences$ = this.abscencesSubject.asObservable();

  public constructor(
    private readonly abscencesHttpRequestService: AbscencesHttpRequestService,
    private readonly engineService: EngineService,
    private readonly abscencesCacheService: AbscencesCacheService
  ) {
    this.engineService.appendProcessorForMany('Abscence', this.processMany.bind(this));
    this.engineService.appendProcessorForOne('Abscence', this.processOne.bind(this));
  }

  public findAll(): void {
    const request = this.abscencesHttpRequestService.findAll();
    this.engineService.execute(new HttpRequest('Abscence', 'findAll', request));
  }

  public findOne(id: string): void {
    const request = this.abscencesHttpRequestService.findOne(id);
    this.engineService.execute(new HttpRequest('Abscence', 'findOne', request));
  }

  public create(abscence: Partial<Abscence>): void {
    const request = this.abscencesHttpRequestService.create(abscence);
    this.engineService.execute(new HttpRequest('Abscence', 'create', request));
  }

  public update(abscence: Abscence): void {
    const request = this.abscencesHttpRequestService.update(abscence);
    this.engineService.execute(new HttpRequest('Abscence', 'update', request));
  }

  public delete(abscence: Abscence): void {
    const request = this.abscencesHttpRequestService.delete(abscence);
    this.engineService.execute(new HttpRequest('Abscence', 'delete', request));
  }

	public getsubstitutionRequest(id: string): void {
		const request = this.abscencesHttpRequestService.getsubstitutionRequest(id);
    this.engineService.execute(new HttpRequest('Abscence', 'substitutionRequest', request));
	}

	public updateSubstitutionRequest(abscence: Abscence): void {
		const request = this.abscencesHttpRequestService.updateSubstitutionStatus(abscence);
    this.engineService.execute(new HttpRequest('Abscence', 'substitutionRequestUpdate', request));
	}

  public processMany(action: string, abscences: Abscence[]): void {
    switch (action) {
      case 'findAll':
        this.abscencesCacheService.abscences = abscences;
        break;
      case 'findOne':
        console.error('Invalid action', action);
        break;
      case 'create':
        this.abscencesCacheService.insertOrUpdateMany(abscences);
        break;
      case 'update':
        this.abscencesCacheService.insertOrUpdateMany(abscences);
        break;
      case 'delete':
        this.abscencesCacheService.deleteMany(abscences);
        break;
      case 'substitutionRequest':
        this.abscencesCacheService.insertResquests(abscences);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }
    this.abscencesSubject.next(this.abscencesCacheService.abscences);

    this.updatedSubject.next();
  }

  public processOne(action: string, abscence: Abscence): void {
    switch (action) {
      case 'findAll':
        console.error('Invalid action', action);
        break;
      case 'findOne':
        this.abscencesCacheService.insertOrUpdateOne(abscence);
        break;
      case 'create':
        this.abscencesCacheService.insertOrUpdateOne(abscence);
        break;
      case 'update':
        this.abscencesCacheService.insertOrUpdateOne(abscence);
        break;
      case 'delete':
        this.abscencesCacheService.deleteOne(abscence);
        break;
			case 'substitutionRequestUpdate':
				this.abscencesCacheService.removeOneRequest(abscence)
				break;
      default:
        console.error('Invalid action', action);
        break;
    }


    this.updatedSubject.next();
  }
}
