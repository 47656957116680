import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'germanFormat'
})
export class GermanNumberFormatPipe implements PipeTransform {
	transform(value: string | number | null): string | null {
		if (value !== null) {
			let stringValue = value.toString();
			stringValue = stringValue.replace(/\./g, 'dot').replace(/,/g, '.').replace(/dot/g, ',');
			return stringValue;
		}
		return value;
	}
}