import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {loadCldr, registerLicense} from '@syncfusion/ej2-base';

import deNumbers from 'cldr-data/main/de/numbers.json';
import deCurrencies from 'cldr-data/main/de/currencies.json';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import currencyData from 'cldr-data/supplemental/currencyData.json';

loadCldr(deNumbers, deCurrencies, numberingSystems, currencyData);

registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxcdHRRR2VZWERxX0Q=')
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
