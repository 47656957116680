import {
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChanges,
	OnInit,
	ViewChild,
	ChangeDetectorRef, AfterViewInit
} from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import {Abscence} from 'src/app/entities/abscences/models/abscence.model'
import {Employee} from "../../entities/employees/models/employee.model";
import {AuthService} from "../../shared/services/auth.service";
import {ToastUtility} from '@syncfusion/ej2-notifications';

type AbwesenheitsartType = 'Bezahlter Urlaub' | 'Krankheit'
import {Toast, ToastComponent, ToastModel} from '@syncfusion/ej2-angular-notifications';
import {DropDownListComponent} from "@syncfusion/ej2-angular-dropdowns";
import {DataService} from "../../shared/services/data.service";
import moment from "moment";
import { TimeService } from 'src/app/shared/services/time.service';

@Component({
	selector: 'leaveRequestDialog',
	templateUrl: './leaveRequestDialog.component.html',
	styleUrls: ['./leaveRequestDialog.component.scss']
})
export class LeaveRequestDialogComponent implements OnInit, AfterViewInit {

	@Output() closed: EventEmitter<string> = new EventEmitter<string>()
	@Output() next: EventEmitter<any> = new EventEmitter<any>()
	@Input() abscence: Abscence;
	form: FormGroup;
	currentEmployee: Employee;
	empolyeeList: any[];
	filteredEmployees: any[];
	public toastObj?: ToastComponent
	absenceType: string
	@ViewChild('absenceTypeDropdown') absenceTypeDropdown: DropDownListComponent;


	// type?: AbwesenheitsartType;
	get type() {
		return this.form.controls['type'].value;
	}

	set type(value: AbwesenheitsartType) {
		this.form.controls['type'].setValue(value);
	}

	// from?: Date
	get from() {
		return this.form?.controls['from'].value;
	}

	set from(value: Date) {
		this.form.controls['from'].setValue(value);
	}

	// to?: Date
	get to() {
		return this.form.controls['to'].value;
	}

	set to(value: Date) {
		this.form.controls['to'].setValue(value);
	}

	// halfDay?: boolean = false
	get halfDay() {
		return this.form.controls['halfDay'].value;
	}

	set halfDay(value: boolean) {
		this.form.controls['halfDay'].setValue(value);
	}

	// substitution?: string
	get substitution() {
		return this.form.controls['substitution'].value;
	}

	set substitution(value: string) {
		this.form.controls['substitution'].setValue(value);
	}

	// comment?: string = ''
	get comment() {
		return this.form.controls['comment'].value;
	}

	set comment(value: string) {
		this.form.controls['comment'].setValue(value);
	}

	getControl(controlName: string) {
		return this.form.controls[controlName] as FormControl
	}

	constructor(private fb: FormBuilder, private authService: AuthService, private dataService: DataService, private cdr: ChangeDetectorRef, private readonly timeService: TimeService) {
		this.form = this.fb.group({
			type: ['', [Validators.required]],
			from: [new Date(), [Validators.required]],
			to: [new Date(), [Validators.required, this.isGreaterThanValidator()]],
			halfDay: [false, [Validators.required]],
			substitution: ['',],
			comment: ['', [Validators.required]]
		})
	}

	ngOnInit() {

		this.getCurrentEmployee();
	}

	ngAfterViewInit() {
		this.absenceType = ' ';
		this.empolyeeList = ['Tom', 'John']
		this.dataService.employeesService.updated$.subscribe(() => {
			this.empolyeeList = this.dataService.employeesCacheService.employees
			// .filter((employee: any) => employee.id !== this.currentEmployee.id)
			// .map((employee: any) => {
			// 	return {
			// 		id: employee.id,
			// 		name: `${employee.firstname} ${employee.lastname}`
			// 	}
			// });
		});

		this.dataService.employeesService.employees$.subscribe((employees) => {
			this.empolyeeList = employees;
			this.filteredEmployees = this.empolyeeList
				.filter((employee: any) => employee.id !== this.currentEmployee.id && !employee.isArchived)
				.map((employee: any) => {
					return {
						id: employee.id,
						name: `${employee.firstname} ${employee.lastname}`
					}
				});
		})
		this.form.controls['substitution'].addValidators(this.substitutionRequiredValidator());
		this.cdr.detectChanges();

	}

	getCurrentEmployee() {
		if (!this.currentEmployee) {
			this.currentEmployee = this.authService.selectedEmployee;
			console.log('Current employee', this.currentEmployee);
		}
		// Subscribe to current user observable to get initial employee data
		this.authService.selectedUser$.subscribe((employee) => {
			this.currentEmployee = employee;
			this.filteredEmployees = this.empolyeeList
				.filter((employee: any) => employee.id !== this.currentEmployee.id && !employee.isArchived)
				.map((employee: any) => {
					return {
						id: employee.id,
						name: `${employee.firstname} ${employee.lastname}`
					}
				});
		});

	}

	substitutionRequiredValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const absenceTypeControl = this.type;
			const absenceType = absenceTypeControl ? absenceTypeControl : null;
			const substitution = control.value;
			if (absenceType === 'Bezahlter Urlaub' && (!substitution || substitution.trim() === '')) {
				return {substitutionRequired: true};
			}
			return null;
		};
	}

	handleLeaveTypeSelection(ev) {
		this.absenceType = ev.itemData.value;
		if (ev.itemData.value === 'Gleitzeit') {
			// Convert 'from' and 'to' dates to moment objects
			const from = moment(this.from).startOf('day');
			const to = moment(this.to).startOf('day');

			let totalHoursRequired = 0;

			for (let m = moment(from); m.isBefore(to) || m.isSame(to); m.add(1, 'days')) {
				// Get the target working hours for the current month
				const targetWorkingHours =  this.currentEmployee.targetWorkingHourses &&
					this.currentEmployee.targetWorkingHourses.find(hours =>
					m.isSameOrAfter(moment(hours.from)) && m.isSameOrBefore(moment(hours.to))
				);

				if (targetWorkingHours) {
					// Get the target working hours for the current day
					const day = m.locale('en').format('dddd').toLowerCase();
					totalHoursRequired += Number(targetWorkingHours[day]);
				}
			}

			if (this.timeService.userOvertime && this.timeService.userOvertime >= totalHoursRequired) {
				this.timeService.userOvertime -= totalHoursRequired;
				console.log(`GLE leave type selected. ${totalHoursRequired} hours subtracted from overtime.`);
				return true;
			} else {
				setTimeout(() => {
					this.absenceTypeDropdown.value = null;
				}, 200);
				let toast: ToastModel = ToastUtility.show('Sie können leider nicht Gleitzeit als Urlaubsart wählen. Nicht genügend Überstunden.', 'Error', 5000);
				return false;
			}
		}
		return true;
	}


	ngOnChanges(changes: SimpleChanges): void {
		if (changes['abscence'] && this['abscence']) {
			this.type = this['abscence'].type as AbwesenheitsartType;
			this.from = this['abscence'].from;
			this.to = this['abscence'].to;
			this.halfDay = this['abscence'].halfDay;
			this.substitution = this['abscence'].substitution;
			this.comment = this['abscence'].comment;
		}
	}

	close() {

		this.closed.emit('closed')
	}

	gotoNext() {

		if (!this.type || !this.from || !this.to) {
			return
		}

		if (!this.handleLeaveTypeSelection({itemData: {value: this.type}})) return;

		const newLeaveRequest: Partial<Abscence> = {
			type: this.type,
			from: this.from,
			to: this.to,
			halfDay: this.halfDay,
			substitution: this.substitution,
			comment: this.comment,
		}

		this.next.emit({newLeaveRequest, currentEmployee: this.currentEmployee})
	}

	isGreaterThanValidator() {
		return (control: AbstractControl): ValidationErrors | null => {
			const to = control.value;
			const from = this.from;
			return (to >= from) ? null : {range: true};
		}
	}

	protected readonly event = event;
	protected readonly Event = Event;
}
