import { Injectable } from '@angular/core'

import { DataService } from './data.service'
import { TimeEntriesService } from '../../entities/time-entries/services/time-entries.service'
import { AbscencesService } from 'src/app/entities/abscences/services/abscences.service'

@Injectable({
  providedIn: 'root'
})
export class InteractionsService {
  // Inject the needed entity services here. Like for example CatService.
  public constructor(
    public readonly dataService: DataService,
    public readonly timeEntriesService: TimeEntriesService,
		public readonly abscencesService: AbscencesService,
  ) {
  }
}
