<div class="container">
	<div class="legend">
		<div class="legend-item">
			<div class="legend-color" style="background-color: green;"></div>
			<span>Bezahlter Urlaub</span>
		</div>
		<div class="legend-item">
			<div class="legend-color" style="background-color: red;"></div>
			<span>Krankheit</span>
		</div>
		<div class="legend-item">
			<div class="legend-color" style="background-color: #7F7F7F;"></div>
			<span>Gleitzeit</span>
		</div>
		<div class="legend-item">
			<div class="legend-color" style="background-color: #FCC419;"></div>
			<span>Elternzeit</span>
		</div>
	</div>

	<!-- Desktop Table -->
	<table class="desktop-table">
		<thead>
			<tr>
				<th>Name</th>
				<th *ngFor="let month of months">{{ month }}</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let employee of employees; let i = index; trackBy: trackByEmployee">
				<ng-container *ngIf="i === 0 || employee.outlet !== employees[i - 1].outlet">
					<tr>
						<td [attr.colspan]="months.length + 1" class="outlet-row">{{ employee.outlet }}</td>
					</tr>
				</ng-container>
				<tr>
					<td>{{ employee.firstname + ' ' + employee.lastname }}</td>
					<td *ngFor="let month of months; let j = index; trackBy: trackByMonth" class="month">
						<table class="inner-table">
							<tr>
								<td *ngFor="let day of daysPerMonth.get(month)!; trackBy: trackByDay" [ngClass]="getCellClassFromField(employee, j, day)">
									<ejs-tooltip [content]="getTooltipFromField(employee, j, day)" position="TopCenter" openDelay="100">
										<span class="tooltip-target">
											<!-- Tooltip content -->
										</span>
									</ejs-tooltip>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</div>
