import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ConfigService } from '../../../core/services/config.service'
import { TargetWorkingHours } from '../models/target-working-hours.model'
import { CreateTargetWorkingHoursDTO } from '../dto/create-target-working-hours.dto'
import { UpdateTargetWorkingHoursDTO } from '../dto/update-target-working-hours.dto'

@Injectable({
	providedIn: 'root'
})
export class TargetWorkingHoursesHttpRequestService {

	private readonly url: string

	public constructor(private readonly config: ConfigService, private readonly http: HttpClient) {

		this.url = `${this.config.baseUrl}/target-working-hourses`
	}

	public findAll(): Observable<TargetWorkingHours[]> {

		return this.http.get<TargetWorkingHours[]>(this.url)
	}

	public findOne(id: string): Observable<TargetWorkingHours> {

		return this.http.get<TargetWorkingHours>(`${this.url}/${id}`)
	}

	public create(targetWorkingHours: Partial<TargetWorkingHours>): Observable<TargetWorkingHours> {

		const dto: CreateTargetWorkingHoursDTO = {
			monday: targetWorkingHours.monday!,
			tuesday: targetWorkingHours.tuesday!,
			wednesday: targetWorkingHours.wednesday!,
			thursday: targetWorkingHours.thursday!,
			friday: targetWorkingHours.friday!,
			saturday: targetWorkingHours.saturday!,
			sunday: targetWorkingHours.sunday!,
			from: targetWorkingHours.from!,
			to: targetWorkingHours.to!,
			employeeId: targetWorkingHours?.employee?.id ?? undefined,
		}

		return this.http.post<TargetWorkingHours>(this.url, dto)
	}

	public update(targetWorkingHours: TargetWorkingHours): Observable<TargetWorkingHours> {

		const dto: UpdateTargetWorkingHoursDTO = {
			monday: targetWorkingHours.monday,
			tuesday: targetWorkingHours.tuesday,
			wednesday: targetWorkingHours.wednesday,
			thursday: targetWorkingHours.thursday,
			friday: targetWorkingHours.friday,
			saturday: targetWorkingHours.saturday,
			sunday: targetWorkingHours.sunday,
			from: targetWorkingHours.from,
			to: targetWorkingHours.to,
			employeeId: targetWorkingHours?.employee?.id ?? undefined,
		}

		return this.http.put<TargetWorkingHours>(`${this.url}/${targetWorkingHours.id}`, dto)
	}

	public delete(targetWorkingHours: TargetWorkingHours): Observable<TargetWorkingHours> {

		return this.http.delete<TargetWorkingHours>(`${this.url}/${targetWorkingHours.id}`)
	}
}
