export const environment = {
  production: true,

  backendProtocol: 'https',
  backendDomain: 'lorenz-url-und-zeiterf.testing.photonic-codes.cloud',
  backendPort: '',
  backendPrefix: 'api',

  keycloakUrl: 'https://kc.testing.photonic-codes.cloud/',
  keycloakRealm: 'lorenz-url-und-zeiterf',
  keycloakClientId: 'lorenz-url-und-zeiterf-client'
};
