<button ejs-dropdownbutton [items]='items' class="notification-wrapper" cssClass='e-caret-hide'
	(select)='select($event)' [disabled]='items.length == 0'>
	<div class="notification-container">
		<span class="notification-badge" *ngIf="notificationCount">{{notificationCount}}</span>
		<svg fill="#000000" height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.95 22.95" xml:space="preserve">
			<g>
				<g>
					<g>
						<path
							d="M21.379 18.76c-2.439 -1.101 -2.532 -5.829 -2.533 -5.948v-3.172c0 -3.053 -1.865 -5.677 -4.516 -6.795C14.324 1.275 13.046 0 11.475 0s-2.849 1.275 -2.855 2.844c-2.651 1.118 -4.516 3.743 -4.516 6.795v3.172c-0.002 0.119 -0.095 4.847 -2.534 5.948a0.647 0.647 0 0 0 0.266 1.237h6.183c0.127 0.697 0.456 1.346 0.961 1.871 0.67 0.698 1.555 1.082 2.494 1.082 0.938 0 1.824 -0.384 2.494 -1.082 0.504 -0.525 0.834 -1.174 0.961 -1.871h6.183a0.647 0.647 0 0 0 0.633 -0.511 0.647 0.647 0 0 0 -0.366 -0.726m-3.213 -2.265c0.256 0.777 0.619 1.558 1.134 2.207H3.649c0.515 -0.649 0.878 -1.43 1.134 -2.207zM11.475 1.294c0.71 0 1.311 0.477 1.499 1.128 -0.484 -0.1 -0.986 -0.153 -1.499 -0.153s-1.015 0.053 -1.499 0.153c0.188 -0.651 0.789 -1.128 1.499 -1.128M5.399 12.815v-3.176c0 -3.35 2.726 -6.076 6.076 -6.076s6.076 2.726 6.076 6.076v3.181c0.001 0.097 0.016 1.12 0.274 2.38H5.125c0.258 -1.261 0.273 -2.286 0.274 -2.385m6.076 8.841c-0.988 0 -1.85 -0.712 -2.127 -1.659h4.253c-0.277 0.947 -1.139 1.659 -2.127 1.659" />
					</g>
				</g>
			</g>
		</svg>
	</div>
</button>
<ejs-dialog #dialog [visible]='visible' width='420px' isModal='true' header='Antrag auf Ersatzperson' showCloseIcon='true'>
	<div class="dialog-content">
		<p>Sie wurden als Ersatz für {{this.selectedNotification.employee?.firstname}}
			{{this.selectedNotification.employee?.lastname}} vom {{this.selectedNotification.from | date: 'dd.MM.yyyy'}} bis
			{{getToDate(this.selectedNotification.to) | date: 'dd.MM.yyyy'}} hinzugefügt. </p>
	</div>
	<div class="e-footer-content">
		<button ejs-button (click)="rejectRequest()">
			Ablehnen
		</button>
		<button ejs-button [isPrimary]="true" (click)="confirmRequest()">
			Bestätigen
		</button>
	</div>
</ejs-dialog>