import { Injectable } from '@angular/core';

import { TargetWorkingHours } from '../models/target-working-hours.model';

@Injectable({
  providedIn: 'root'
})
export class TargetWorkingHoursesCacheService {
  public targetworkinghourses: TargetWorkingHours[] = [];

  public insertOrUpdateMany(targetworkinghourses: TargetWorkingHours[]): void {
    for (const targetworkinghours of targetworkinghourses) {
      this.insertOrUpdateOne(targetworkinghours);
    }
  }

  public insertOrUpdateOne(targetworkinghours: TargetWorkingHours): void {
    const index = this.targetworkinghourses.findIndex(t => t.id === targetworkinghours.id);

    if (index === -1) {
      this.targetworkinghourses.push(targetworkinghours);
    } else {
      this.targetworkinghourses[index] = targetworkinghours;
    }
  }

  public deleteMany(targetworkinghourses: TargetWorkingHours[]): void {
    for (const targetworkinghours of targetworkinghourses) {
      this.deleteOne(targetworkinghours);
    }
  }

  public deleteOne(targetworkinghours: TargetWorkingHours): void {
    const index = this.targetworkinghourses.findIndex(t => t.id === targetworkinghours.id);

    if (index === -1) {
      return;
    }

    this.targetworkinghourses.splice(index, 1);
  }
}
