import { Injectable } from '@angular/core';

import { Holiday } from '../models/holiday.model';

@Injectable({
  providedIn: 'root'
})
export class HolidaysCacheService {
  public holidays: Holiday[] = [];

  public insertOrUpdateMany(holidays: Holiday[]): void {
    for (const holiday of holidays) {
      this.insertOrUpdateOne(holiday);
    }
  }

  public insertOrUpdateOne(holiday: Holiday): void {
    const index = this.holidays.findIndex(h => h.id === holiday.id);

    if (index === -1) {
      this.holidays.push(holiday);
    } else {
      this.holidays[index] = holiday;
    }
  }

  public deleteMany(holidays: Holiday[]): void {
    for (const holiday of holidays) {
      this.deleteOne(holiday);
    }
  }

  public deleteOne(holiday: Holiday): void {
    const index = this.holidays.findIndex(h => h.id === holiday.id);

    if (index === -1) {
      return;
    }

    this.holidays.splice(index, 1);
  }
}
