import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TargetVacationDays } from '../models/target-vacation-days.model';
import { TargetVacationDaysesHttpRequestService } from './target-vacation-dayses-http-request.service';
import { HttpRequest } from '../../../engine/models/http-request.model';
import { EngineService } from '../../../engine/services/engine.service';
import { TargetVacationDaysesCacheService } from './target-vacation-dayses-cache.service';

@Injectable({
  providedIn: 'root'
})
export class TargetVacationDaysesService {
  private readonly updatedSubject: Subject<void> = new Subject<void>();
  public readonly updated$: Observable<void> = this.updatedSubject.asObservable();

  public constructor(
    private readonly targetvacationdaysesHttpRequestService: TargetVacationDaysesHttpRequestService,
    private readonly engineService: EngineService,
    private readonly targetvacationdaysesCacheService: TargetVacationDaysesCacheService
  ) {
    this.engineService.appendProcessorForMany('TargetVacationDays', this.processMany.bind(this));
    this.engineService.appendProcessorForOne('TargetVacationDays', this.processOne.bind(this));
    console.log('Appended response processors for TargetVacationDays');
  }

  public findAll(): void {
    const request = this.targetvacationdaysesHttpRequestService.findAll();
    this.engineService.execute(new HttpRequest('TargetVacationDays', 'findAll', request));
  }

  public findOne(id: string): void {
    const request = this.targetvacationdaysesHttpRequestService.findOne(id);
    this.engineService.execute(new HttpRequest('TargetVacationDays', 'findOne', request));
  }

  public create(targetvacationdays: Partial<TargetVacationDays>): void {
    const request = this.targetvacationdaysesHttpRequestService.create(targetvacationdays);
    this.engineService.execute(new HttpRequest('TargetVacationDays', 'create', request));
  }

  public update(targetvacationdays: TargetVacationDays): void {
    const request = this.targetvacationdaysesHttpRequestService.update(targetvacationdays);
    this.engineService.execute(new HttpRequest('TargetVacationDays', 'update', request));
  }

  public delete(targetvacationdays: TargetVacationDays): void {
    const request = this.targetvacationdaysesHttpRequestService.delete(targetvacationdays);
    this.engineService.execute(new HttpRequest('TargetVacationDays', 'delete', request));
  }

  public processMany(action: string, targetvacationdayses: TargetVacationDays[]): void {
    switch (action) {
      case 'findAll':
        this.targetvacationdaysesCacheService.targetvacationdayses = targetvacationdayses;
        break;
      case 'findOne':
        console.error('Invalid action', action);
        break;
      case 'create':
        this.targetvacationdaysesCacheService.insertOrUpdateMany(targetvacationdayses);
        break;
      case 'update':
        this.targetvacationdaysesCacheService.insertOrUpdateMany(targetvacationdayses);
        break;
      case 'delete':
        this.targetvacationdaysesCacheService.deleteMany(targetvacationdayses);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }

  public processOne(action: string, targetvacationdays: TargetVacationDays): void {
    switch (action) {
      case 'findAll':
        console.error('Invalid action', action);
        break;
      case 'findOne':
        this.targetvacationdaysesCacheService.insertOrUpdateOne(targetvacationdays);
        break;
      case 'create':
        this.targetvacationdaysesCacheService.insertOrUpdateOne(targetvacationdays);
        break;
      case 'update':
        this.targetvacationdaysesCacheService.insertOrUpdateOne(targetvacationdays);
        break;
      case 'delete':
        this.targetvacationdaysesCacheService.deleteOne(targetvacationdays);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }
    this.updatedSubject.next();
  }
}
