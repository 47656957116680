import { Injectable } from '@angular/core';

import { Employee } from '../models/employee.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeesCacheService {
  public employees: Employee[] = [];

  public insertOrUpdateMany(employees: Employee[]): void {
    for (const employee of employees) {
      this.insertOrUpdateOne(employee);
    }
  }

  public insertOrUpdateOne(employee: Employee): void {
    const index = this.employees.findIndex(e => e.id === employee.id);

    if (index === -1) {
      this.employees.push(employee);
    } else {
      this.employees[index] = employee;
    }
  }

  public deleteMany(employees: Employee[]): void {
    for (const employee of employees) {
      this.deleteOne(employee);
    }
  }

  public deleteOne(employee: Employee): void {
    const index = this.employees.findIndex(e => e.id === employee.id);

    if (index === -1) {
      return;
    }

    this.employees.splice(index, 1);
  }
}
