<div class="main-container">
	<div class="Zeiterfassung">
		<div class="upperRow">
			<div class="richText">
				<div><strong>{{ selectedDate | date:'longDate' }}</strong></div>
			</div>
			<div class="arrows">
				<svg (click)="navigate('prev')" class="arrowSVG" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none"
						 width="24"
						 xmlns="http://www.w3.org/2000/svg" style="-webkit-print-color-adjust: exact"
						 id="screenshot-ba340de7-9585-802a-8003-8ca264030388" version="1.1" viewBox="796 -4252.5 24 24" height="24">
					<g id="shape-ba340de7-9585-802a-8003-8ca264030388" style="fill: #000000" width="24" height="24" rx="0" ry="0">
						<g id="shape-ba340de7-9585-802a-8003-8ca2640303c1">
							<g class="fills" id="fills-ba340de7-9585-802a-8003-8ca2640303c1">
								<path rx="0" ry="0" style="fill: #000000"
											d="M806.828,-4240.500L811.778,-4235.550L810.364,-4234.135L804.000,-4240.500L810.364,-4246.864L811.778,-4245.450L806.828,-4240.500ZZ"></path>
							</g>
						</g>
					</g>
				</svg>
				<svg (click)="navigate('next')" class="arrowSVG" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none"
						 width="24"
						 xmlns="http://www.w3.org/2000/svg" style="-webkit-print-color-adjust: exact"
						 id="screenshot-ba340de7-9585-802a-8003-8ca264030389" version="1.1" viewBox="827 -4252.5 24 24" height="24">
					<g id="shape-ba340de7-9585-802a-8003-8ca264030389" style="fill: #000000" width="24" height="24" rx="0" ry="0">
						<g id="shape-ba340de7-9585-802a-8003-8ca2640303c3">
							<g class="fills" id="fills-ba340de7-9585-802a-8003-8ca2640303c3">
								<path rx="0" ry="0" style="fill: #000000"
											d="M840.172,-4240.500L835.222,-4245.450L836.636,-4246.865L843.000,-4240.500L836.636,-4234.136L835.222,-4235.550L840.172,-4240.500ZZ"></path>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</div>
		<div class="secondRow">
			<div class="timeEntry timeEntryForm">
				<form [formGroup]="form" class="form">
					<div class="fieldRow">

						<div class="field">
							<!-- Date -->
							<ejs-datepicker
								locale="de"
								[firstDayOfWeek]="1"
								id="date"
								[placeholder]="'Datum'"
								format="dd.MM.yyyy"
								formControlName="date"
							></ejs-datepicker>
							<div *ngIf="getControl('date').errors as errors">
								<div *ngIf="errors['required'] && getControl('date').touched" class="e-error">
									Dieses Feld ist erforderlich.
								</div>
							</div>
						</div>

						<div class="field">
							<button [disabled]="form.invalid" ejs-button [cssClass]="'e-'+''" [isPrimary]="+'1' == 1"
								(click)="submitTimeEntry()"
							>
								Zeiteintrag speichern
							</button>
						</div>
					</div>
					<div class="fieldRow">
						<div class="field timepicker">
							<!-- From -->
							<ejs-timepicker
								[step]="+'15'"
								[placeholder]="'Start'"
								format="HH:mm"
								formControlName="from"
							></ejs-timepicker>
							<div *ngIf="getControl('from').errors">
								<div *ngIf="getControlErrors('from', 'required') && getControl('from').touched" class="e-error">
									Dieses Feld ist erforderlich.
								</div>
								<div *ngIf="getControlErrors('from', 'range') && getControl('from').touched" class="e-error">
									Die Endzeit kann nicht vor der Startzeit liegen.
								</div>
							</div>
						</div>

						<div class="field timepicker">
							<!-- To -->
							<ejs-timepicker
								[step]="+'15'"
								[placeholder]="'Ende'"
								format="HH:mm"
								formControlName="to"
							></ejs-timepicker>
							<div *ngIf="getControl('to').errors">
								<div *ngIf="getControlErrors('to', 'required') && getControl('to').touched" class="e-error">
									Dieses Feld ist erforderlich.
								</div>
								<div *ngIf="getControlErrors('to', 'range') && getControl('to').touched" class="e-error">
									Die Endzeit kann nicht vor der Startzeit liegen.
								</div>
							</div>
						</div>

						<div class="field timepicker">

							<!-- Pause -->
							<ejs-timepicker
								[step]="+'15'"
								[placeholder]="'Pause'"
								format="HH:mm"
								formControlName="pause"
							></ejs-timepicker>
							<div *ngIf="getControl('pause').errors as errors">
								<div *ngIf="errors['required'] && getControl('pause').touched" class="e-error">
									Dieses Feld ist erforderlich.
								</div>
								<div *ngIf="errors['total'] && getControl('pause').touched" class="e-error">
									Die Pausenzeit darf nicht länger oder gleich der Gesamtzeit sein.
								</div>
							</div>
							<div *ngIf="isPauseRequired()" class="e-error">
								{{ pauseWarningMessage }}
							</div>
						</div>
					</div>
					<div class="bottomRow">

						<div class="field" style="width: 100%">

							<!-- Comment -->
							<ejs-textbox style="width: 100%;"
													 #textarea
													 [placeholder]="'Kommentar'"
													 [floatLabelType]="'Auto'"
													 [cssClass]="'e-'+''"
													 rows="3"
													 class="invalid"
													 [multiline]="true"
													 formControlName="comment"
							></ejs-textbox>
						</div>

					</div>
				</form>

			</div>
			<div class="timeEntry">
				<div class="Layout">
					<div class="richText2">
						<div>
							<span style="color: rgb(102, 102, 102)">Arbeitszeitkonto</span>
						</div>
					</div>
					<div class="timeBlockRow">
						<div class="timeBlock">
							<div class="time">
								<div><strong>{{ timeService.recordedHours | germanFormat }} h</strong></div>
							</div>
							<div class="time2Label">
								<div><span style="color: rgb(102, 102, 102)">Erfasst</span></div>
							</div>
						</div>
						<div class="timeBlock">
							<div class="time">
								<div><strong>{{ timeService.targetWorkingHours | germanFormat }} h</strong></div>
							</div>
							<div class="time2Label">
								<div><span style="color: rgb(102, 102, 102)">Monatsziel</span></div>
							</div>
						</div>
						<div class="timeBlock">
							<div class="time2">
								<div><strong>{{ (timeService.leftoverHours >= 0 ? timeService.leftoverHours : -timeService.leftoverHours) | germanFormat }} h</strong></div>
							</div>
							<div class="time2Label2">
								<div><span style="color: rgb(102, 102, 102)">{{ timeService.leftoverHours >= 0 ? 'Übrig' : 'Über' }}</span></div>
							</div>
						</div>
					</div>
				</div>
				<div class="Layout2">
					<div class="timeBlock">
						<div class="time2">
							<div><strong>{{ (timeService.userOvertime >= 0 ? timeService.userOvertime : -timeService.userOvertime) | germanFormat }} h</strong></div>
						</div>
						<div class="time2Label2">
							<div><span style="color: rgb(102, 102, 102)">{{ timeService.userOvertime >= 0 ? 'Überstunden' : 'Unterstunden' }}</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ejs-scheduler-container">
			<ejs-schedule class="ejs-scheduler" locale='de' #scheduleObj width='100%' [selectedDate]="selectedDate"
										[eventSettings]="eventSettings"  [firstDayOfWeek]="1" [startHour]="'06:00'" [endHour]="'20:00'"
										[(currentView)]="scheduleView" [allowMultiCellSelection]="true" [quickInfoOnSelectionEnd]="true"
										[allowMultiRowSelection]="true" (eventRendered)="onEventRendered($event)"
										(actionComplete)="onActionComplete($event)" (popupOpen)="onPopupOpen($event)">
				<e-views>
					<e-view option="Day"></e-view>
					<e-view option="Week"></e-view>
					<e-view option="WorkWeek"></e-view>
					<e-view option="Month"></e-view>
				</e-views>
			</ejs-schedule>
		</div>
	</div>
</div>
