import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ConfigService } from '../../../core/services/config.service'
import { TimeEntry } from '../models/time-entry.model'
import { CreateTimeEntryDTO } from '../dto/create-time-entry.dto'
import { UpdateTimeEntryDTO } from '../dto/update-time-entry.dto'

@Injectable({
	providedIn: 'root'
})
export class TimeEntriesHttpRequestService {

	private readonly url: string

	public constructor(private readonly config: ConfigService, private readonly http: HttpClient) {

		this.url = `${this.config.baseUrl}/time-entries`
	}

	public findAll(): Observable<TimeEntry[]> {

		return this.http.get<TimeEntry[]>(this.url)
	}

	public findOne(id: string): Observable<TimeEntry> {

		return this.http.get<TimeEntry>(`${this.url}/${id}`)
	}

	public create(timeEntry: Partial<TimeEntry>): Observable<TimeEntry> {

		const dto: CreateTimeEntryDTO = {
			from: timeEntry.from!,
			to: timeEntry.to!,
			date: timeEntry.date!,
			pause: timeEntry.pause!,
			comment: timeEntry.comment!,
			employeeId: timeEntry?.employee?.id ?? undefined,
		}

		return this.http.post<TimeEntry>(this.url, dto)
	}

	public update(timeEntry: TimeEntry): Observable<TimeEntry> {

		const dto: UpdateTimeEntryDTO = {
			from: timeEntry.from,
			to: timeEntry.to,
			date: timeEntry.date,
			pause: timeEntry.pause,
			comment: timeEntry.comment,
			employeeId: timeEntry?.employee?.id ?? undefined,
		}

		return this.http.put<TimeEntry>(`${this.url}/${timeEntry.id}`, dto)
	}

	public delete(timeEntry: TimeEntry): Observable<TimeEntry> {

		return this.http.delete<TimeEntry>(`${this.url}/${timeEntry.id}`)
	}
}
