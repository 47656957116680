import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ConfigService } from '../../../core/services/config.service'
import { TargetVacationDays } from '../models/target-vacation-days.model'
import { CreateTargetVacationDaysDTO } from '../dto/create-target-vacation-days.dto'
import { UpdateTargetVacationDaysDTO } from '../dto/update-target-vacation-days.dto'

@Injectable({
	providedIn: 'root'
})
export class TargetVacationDaysesHttpRequestService {

	private readonly url: string

	public constructor(private readonly config: ConfigService, private readonly http: HttpClient) {

		this.url = `${this.config.baseUrl}/target-vacation-dayses`
	}

	public findAll(): Observable<TargetVacationDays[]> {

		return this.http.get<TargetVacationDays[]>(this.url)
	}

	public findOne(id: string): Observable<TargetVacationDays> {

		return this.http.get<TargetVacationDays>(`${this.url}/${id}`)
	}

	public create(targetVacationDays: Partial<TargetVacationDays>): Observable<TargetVacationDays> {

		const dto: CreateTargetVacationDaysDTO = {
			amount: targetVacationDays.amount!,
			from: targetVacationDays.from!,
			to: targetVacationDays.to!,
			employeeId: targetVacationDays?.employee?.id ?? undefined,
		}

		return this.http.post<TargetVacationDays>(this.url, dto)
	}

	public update(targetVacationDays: TargetVacationDays): Observable<TargetVacationDays> {

		const dto: UpdateTargetVacationDaysDTO = {
			amount: targetVacationDays.amount,
			from: targetVacationDays.from,
			to: targetVacationDays.to,
			employeeId: targetVacationDays?.employee?.id ?? undefined,
		}

		return this.http.put<TargetVacationDays>(`${this.url}/${targetVacationDays.id}`, dto)
	}

	public delete(targetVacationDays: TargetVacationDays): Observable<TargetVacationDays> {

		return this.http.delete<TargetVacationDays>(`${this.url}/${targetVacationDays.id}`)
	}
}
