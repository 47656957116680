import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TimeEntry } from '../models/time-entry.model';
import { TimeEntriesHttpRequestService } from './time-entries-http-request.service';
import { HttpRequest } from '../../../engine/models/http-request.model';
import { EngineService } from '../../../engine/services/engine.service';
import { TimeEntriesCacheService } from './time-entries-cache.service';

@Injectable({
  providedIn: 'root'
})
export class TimeEntriesService {
  private readonly updatedSubject: Subject<void> = new Subject<void>();
  public readonly updated$: Observable<void> = this.updatedSubject.asObservable();

  public constructor(
    private readonly timeentriesHttpRequestService: TimeEntriesHttpRequestService,
    private readonly engineService: EngineService,
    private readonly timeentriesCacheService: TimeEntriesCacheService
  ) {
    this.engineService.appendProcessorForMany('TimeEntry', this.processMany.bind(this));
    this.engineService.appendProcessorForOne('TimeEntry', this.processOne.bind(this));
  }

  public findAll(): void {
    const request = this.timeentriesHttpRequestService.findAll();
    this.engineService.execute(new HttpRequest('TimeEntry', 'findAll', request));
  }

  public findOne(id: string): void {
    const request = this.timeentriesHttpRequestService.findOne(id);
    this.engineService.execute(new HttpRequest('TimeEntry', 'findOne', request));
  }

  public create(timeentry: Partial<TimeEntry>): void {
    const request = this.timeentriesHttpRequestService.create(timeentry);
    this.engineService.execute(new HttpRequest('TimeEntry', 'create', request));
  }

  public update(timeentry: TimeEntry): void {
    const request = this.timeentriesHttpRequestService.update(timeentry);
    this.engineService.execute(new HttpRequest('TimeEntry', 'update', request));
  }

  public delete(timeentry: TimeEntry): void {
    const request = this.timeentriesHttpRequestService.delete(timeentry);
    this.engineService.execute(new HttpRequest('TimeEntry', 'delete', request));
  }

  public processMany(action: string, timeentries: TimeEntry[]): void {
    switch (action) {
      case 'findAll':
        this.timeentriesCacheService.timeentries = timeentries;
        break;
      case 'findOne':
        console.error('Invalid action', action);
        break;
      case 'create':
        this.timeentriesCacheService.insertOrUpdateMany(timeentries);
        break;
      case 'update':
        this.timeentriesCacheService.insertOrUpdateMany(timeentries);
        break;
      case 'delete':
        this.timeentriesCacheService.deleteMany(timeentries);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }

  public processOne(action: string, timeentry: TimeEntry): void {
    switch (action) {
      case 'findAll':
        console.error('Invalid action', action);
        break;
      case 'findOne':
        this.timeentriesCacheService.insertOrUpdateOne(timeentry);
        break;
      case 'create':
        this.timeentriesCacheService.insertOrUpdateOne(timeentry);
        break;
      case 'update':
        this.timeentriesCacheService.insertOrUpdateOne(timeentry);
        break;
      case 'delete':
        this.timeentriesCacheService.deleteOne(timeentry);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }
}
