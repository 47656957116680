import { Injectable } from '@angular/core';

import { Abscence } from '../models/abscence.model';

@Injectable({
  providedIn: 'root'
})
export class AbscencesCacheService {
  public abscences: Abscence[] = [];
	public substitutionRequest: Abscence[] = [];

  public insertOrUpdateMany(abscences: Abscence[]): void {
    for (const abscence of abscences) {
      this.insertOrUpdateOne(abscence);
    }
  }

  public insertOrUpdateOne(abscence: Abscence): void {
    const index = this.abscences.findIndex(a => a.id === abscence.id);

    if (index === -1) {
      this.abscences.push(abscence);
    } else {
      this.abscences[index] = abscence;
    }
  }

  public deleteMany(abscences: Abscence[]): void {
    for (const abscence of abscences) {
      this.deleteOne(abscence);
    }
  }

  public deleteOne(abscence: Abscence): void {
    const index = this.abscences.findIndex(a => a.id === abscence.id);

    if (index === -1) {
      return;
    }

    this.abscences.splice(index, 1);
  }

	public insertResquests(requests: Abscence[]): void {
		for (const request of requests) {
      this.insertOneResquest(request);
    }
	}

	public insertOneResquest(request: Abscence): void {
    const index = this.substitutionRequest.findIndex(a => a.id === request.id);

    if (index === -1) {
      this.substitutionRequest.push(request);
    } else {
      this.substitutionRequest[index] = request;
    }
  }

	public removeOneRequest(request: Abscence): void {
    const index = this.substitutionRequest.findIndex(a => a.id === request.id);

    if (index === -1) {
      return;
    }

    this.substitutionRequest.splice(index, 1);
	}
}
