import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Holiday } from '../models/holiday.model';
import { HolidaysHttpRequestService } from './holidays-http-request.service';
import { HttpRequest } from '../../../engine/models/http-request.model';
import { EngineService } from '../../../engine/services/engine.service';
import { HolidaysCacheService } from './holidays-cache.service';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {
  private readonly updatedSubject: Subject<void> = new Subject<void>();
  public readonly updated$: Observable<void> = this.updatedSubject.asObservable();

  public constructor(
    private readonly holidaysHttpRequestService: HolidaysHttpRequestService,
    private readonly engineService: EngineService,
    private readonly holidaysCacheService: HolidaysCacheService
  ) {
    this.engineService.appendProcessorForMany('Holiday', this.processMany.bind(this));
    this.engineService.appendProcessorForOne('Holiday', this.processOne.bind(this));
  }

  public findAll(): void {
    const request = this.holidaysHttpRequestService.findAll();
    this.engineService.execute(new HttpRequest('Holiday', 'findAll', request));
  }

  public findOne(id: string): void {
    const request = this.holidaysHttpRequestService.findOne(id);
    this.engineService.execute(new HttpRequest('Holiday', 'findOne', request));
    console.log('Requesting a Holiday');
  }

  public create(holiday: Partial<Holiday>): void {
    const request = this.holidaysHttpRequestService.create(holiday);
    this.engineService.execute(new HttpRequest('Holiday', 'create', request));
  }

  public update(holiday: Holiday): void {
    const request = this.holidaysHttpRequestService.update(holiday);
    this.engineService.execute(new HttpRequest('Holiday', 'update', request));
  }

  public delete(holiday: Holiday): void {
    const request = this.holidaysHttpRequestService.delete(holiday);
    this.engineService.execute(new HttpRequest('Holiday', 'delete', request));
  }

  public processMany(action: string, holidays: Holiday[]): void {
    switch (action) {
      case 'findAll':
        this.holidaysCacheService.holidays = holidays;
        this.holidaysCacheService.holidays.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'findOne':
        console.error('Invalid action', action);
        break;
      case 'create':
        this.holidaysCacheService.insertOrUpdateMany(holidays);
        break;
      case 'update':
        this.holidaysCacheService.insertOrUpdateMany(holidays);
        break;
      case 'delete':
        this.holidaysCacheService.deleteMany(holidays);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }

  public processOne(action: string, holiday: Holiday): void {
    switch (action) {
      case 'findAll':
        console.error('Invalid action', action);
        break;
      case 'findOne':
        this.holidaysCacheService.insertOrUpdateOne(holiday);
        break;
      case 'create':
        this.holidaysCacheService.insertOrUpdateOne(holiday);
        break;
      case 'update':
        this.holidaysCacheService.insertOrUpdateOne(holiday);
        break;
      case 'delete':
        this.holidaysCacheService.deleteOne(holiday);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }
}
