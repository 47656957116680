<div class="overlay">
	<input #fileUpload type="file" style="display: none" (change)="onFileChange($event)"/>
	<button ejs-button class="e-control e-btn e-lib e- e-primary" (click)="fileUpload.click()" style="margin: 5px">
		Mitarbeiter importieren
	</button>
	<ejs-tab id="element" style="margin: 20px 5px">
		<e-tabitems>
			<e-tabitem>
				<ng-template #headerText>
					<div>Aktiv</div>
				</ng-template>
				<ng-template #content>
					<ejs-grid #mainTable [dataSource]='dataSource' locale="de" allowPaging='true' [allowFiltering]="true" allowSorting="true" [filterSettings]="{ mode: 'Immediate' }">
						<e-columns>
							<e-column field='firstname' headerText='Vorname' width='75'></e-column>
							<e-column field='lastname' headerText='Nachname' width='75'></e-column>
							<e-column field='email' headerText='Email' width='125'></e-column>
							<e-column field='birthday' headerText='Geburtstag' width='50' type='date' format='yMd'></e-column>
							<e-column field='stateToApply' headerText='Bundesland' width='50'></e-column>
							<e-column field='role' headerText='Rolle' width='70'>
								<ng-template #template let-data>
									<div>{{ roleTypes[data.role] }}</div>
								</ng-template>
							</e-column>
							<e-column field="outlet" headerText="Niederlassung" width='70'></e-column>
							<e-column field='remainingVacationDays' textAlign="center" headerText='Kontigent Laufendes Jahr'
												width='100'></e-column>
							<e-column headerText='' textAlign='Center' width='100'>
								<ng-template #template let-data>
									<div class="iconRow">
            <span role="button" (click)="onEditClick(data)" class="material-symbols-outlined action-btn">
              edit
            </span>
										<span role="button" class="material-symbols-outlined action-btn">
              edit_calendar
            </span>
										<span (click)="onEmployeeDelete(data)" class="material-symbols-outlined action-btn">
              delete
            </span>
									</div>
								</ng-template>
							</e-column>
						</e-columns>
					</ejs-grid>
				</ng-template>
			</e-tabitem>
			<e-tabitem>
				<ng-template #headerText>
					<div>Archiviert</div>
				</ng-template>
				<ng-template #content>
					<ejs-grid [dataSource]='archivedEmployees' allowPaging='true' [allowFiltering]="true">
						<e-columns>
							<e-column headerText='Name' width='100'>
								<ng-template #template let-data>
									<div>{{ data.firstname }} {{ data.lastname }}</div>
								</ng-template>
							</e-column>
							<e-column field='email' headerText='Email' width='100'></e-column>
							<e-column field='birthday' headerText='Geburtstag' width='50' type='date' [valueAccessor]="dateAccessor"
												format='yMd'></e-column>
							<e-column field='stateToApply' headerText='Bundesland' width='50'></e-column>
							<e-column field='role' headerText='Rolle' width='70'>
								<ng-template #template let-data>
									<div>{{ roleTypes[data.role] }}</div>
								</ng-template>
							</e-column>
							<e-column field="outlet" headerText="Niederlassung" width='70'></e-column>
							<e-column field='remainingVacationDays' textAlign="center" headerText='Kontigent Laufendes Jahr'
												width='100'></e-column>
						</e-columns>
					</ejs-grid>
				</ng-template>
			</e-tabitem>
		</e-tabitems>
	</ejs-tab>

	<div class="add-user">
    <span role="button" (click)="onAddClick()" class="material-symbols-outlined icon pointer">
      add_circle
    </span>
		<span class="text pointer" (click)="onAddClick()">Neuen Benutzer anlegen</span>
	</div>
</div>

<ejs-dialog #dialog [(visible)]='dialogVisible' header='{{sectionName}}' isModal='true' class="dialog">
	<ng-template #content>
		<div class="employee-dialog" [ngClass]="{'mobile': isMobile()}">
			<div class="sections">
				<button (click)="changeSection(0)" ejs-button class="e-flat">Personaldaten</button>
				<button (click)="changeSection(1)" ejs-button class="e-flat">Urlaubsabspruch</button>
				<button (click)="changeSection(2)" ejs-button class="e-flat">Arbeitszeit</button>
			</div>
			<div class="forms">
				<form *ngIf="employeeForm" [formGroup]="employeeForm">
					<ng-container *ngIf="currentSectionIndex === 0" formGroupName="employee">
						<div class="field">
							<ejs-textbox locale="de" formControlName="firstname" floatLabelType='Auto'
													 placeholder='Vorname'></ejs-textbox>
							<div *ngIf="employeeForm_.get('firstname')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('firstname')?.touched" class="e-error">Dieses Feld
									ist erforderlich.
								</div>
							</div>
						</div>
						<div class="field">
							<ejs-textbox locale="de" formControlName="lastname" floatLabelType='Auto'
													 placeholder='Nachname'></ejs-textbox>
							<div *ngIf="employeeForm_.get('lastname')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('lastname')?.touched" class="e-error">Dieses Feld
									ist erforderlich.
								</div>
							</div>
						</div>
						<div class="field">
							<ejs-dropdownlist locale="de" formControlName="role" floatLabelType='Auto' placeholder='Rolle'
																[fields]="{ text: 'label', value: 'value' }" [dataSource]="roles"></ejs-dropdownlist>
							<div *ngIf="employeeForm_.get('role')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('role')?.touched" class="e-error">Dieses Feld ist
									erforderlich.
								</div>
							</div>
						</div>
						<div class="field">
							<ejs-dropdownlist locale="de" formControlName="outlet" floatLabelType='Auto' placeholder='Niederlassung'
																[dataSource]="outlets"></ejs-dropdownlist>
							<div *ngIf="employeeForm_.get('outlet')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('outlet')?.touched" class="e-error">Dieses Feld ist
									erforderlich.
								</div>
							</div>
						</div>
						<div class="field">
							<ejs-textbox locale="de" formControlName="email" floatLabelType='Auto' placeholder='Email'></ejs-textbox>
							<div *ngIf="employeeForm_.get('email')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('email')?.touched" class="e-error">Dieses Feld ist
									erforderlich.
								</div>
							</div>
						</div>
						<div class="field">
							<ejs-datepicker locale="de" [firstDayOfWeek]="1" formControlName="birthday" floatLabelType='Auto'
															placeholder="Geburtstag"></ejs-datepicker>
							<div *ngIf="employeeForm_.get('birthday')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('birthday')?.touched" class="e-error">Dieses Feld
									ist erforderlich.
								</div>
							</div>
						</div>
						<div class="field">
							<ejs-datepicker locale="de" [firstDayOfWeek]="1" formControlName="startDate" floatLabelType='Auto'
															placeholder="Startdatum des Mitarbeiters"></ejs-datepicker>
							<div *ngIf="employeeForm_.get('startDate')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('startDate')?.touched" class="e-error">Dieses Feld
									ist erforderlich.
								</div>
							</div>
						</div>
						<div class="field">
							<ejs-dropdownlist locale="de" formControlName="stateToApply" floatLabelType="Auto"
																placeholder="Bundesland" [dataSource]="germanStates"></ejs-dropdownlist>
							<div *ngIf="employeeForm_.get('stateToApply')?.errors as errors">
								<div *ngIf="errors['required'] && employeeForm_.get('stateToApply')?.touched" class="e-error">Dieses
									Feld ist erforderlich.
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="currentSectionIndex === 1" formArrayName="vacationEntitlement">
						<div class="vacation-entitlement-container">
							<button ejs-button [isPrimary]="true" (click)="pushVacationEntitlementForm()">Neuer Urlaubsanspruch
							</button>
							<table class="vacation-table">
								<tr>
									<th class="text-align-start">ZEITRAUM</th>
									<th class="text-align-start">KONTINGENT</th>
									<th></th>
								</tr>
								<tr *ngFor="let vacations of vacationEntitlement.controls; let i = index" [formGroupName]="i">
									<td>
										<div class="field">
											<ejs-daterangepicker [firstDayOfWeek]="1" formControlName="dateRange"
																					 locale="de"></ejs-daterangepicker>
											<div *ngIf="vacationEntitlement.at(i).get('dateRange')?.errors as errors">
												<div *ngIf="errors['required'] && vacationEntitlement.at(i).get('dateRange')?.touched"
														 class="e-error">Dieses Feld ist erforderlich.
												</div>
												<div *ngIf="errors['coincidingRange'] && vacationEntitlement.at(i).get('dateRange')?.touched"
														 class="e-error">Dieser Datumsbereich sollte nicht mit dem vorherigen Datumsbereich
													übereinstimmen.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale='de' showSpinButton="false" formControlName="amount"
																					[decimals]="1"></ejs-numerictextbox>
											<div *ngIf="vacationEntitlement.at(i).get('amount')?.errors as errors">
												<div *ngIf="errors['required'] && vacationEntitlement.at(i).get('amount')?.touched"
														 class="e-error">Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td (click)="removeVacationEntitlementForm(i)">
										<span role="button" class="material-symbols-outlined icon">close</span>
									</td>
								</tr>
							</table>
						</div>
					</ng-container>
					<!-- Desktop Version -->
					<ng-container *ngIf="currentSectionIndex === 2 && !isMobile()" formArrayName="workingHours">
						<div class="vacation-entitlement-container">
							<button ejs-button [isPrimary]="true" (click)="pushWorkingHoursForm()">Neue Arbeitszeit</button>
							<table class="vacation-table">
								<tr>
									<th class="text-align-start">ZEITRAUM</th>
									<th class="text-align-start">MO</th>
									<th class="text-align-start">DI</th>
									<th class="text-align-start">MI</th>
									<th class="text-align-start">DO</th>
									<th class="text-align-start">FR</th>
									<th class="text-align-start">SA</th>
									<th class="text-align-start">SO</th>
									<th></th>
								</tr>
								<tr *ngFor="let vacations of workingHours.controls; let i = index" [formGroupName]="i">
									<td style="min-width: 200px">
										<div class="field">
											<ejs-daterangepicker [firstDayOfWeek]="1" locale="de" showSpinButton="false"
																					 formControlName="dateRange"></ejs-daterangepicker>
											<div *ngIf="workingHours.at(i).get('dateRange')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('dateRange')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
												<div *ngIf="errors['coincidingRange'] && vacationEntitlement.at(i).get('dateRange')?.touched"
														 class="e-error">Dieser Datumsbereich sollte nicht mit dem vorherigen Datumsbereich übereinstimmen.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale="de" showSpinButton="false"
																					formControlName="monday"></ejs-numerictextbox>
											<div *ngIf="workingHours.at(i).get('monday')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('monday')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale="de" showSpinButton="false"
																					formControlName="tuesday"></ejs-numerictextbox>
											<div *ngIf="workingHours.at(i).get('tuesday')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('tuesday')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale="de" showSpinButton="false"
																					formControlName="wednesday"></ejs-numerictextbox>
											<div *ngIf="workingHours.at(i).get('wednesday')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('wednesday')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale="de" showSpinButton="false"
																					formControlName="thursday"></ejs-numerictextbox>
											<div *ngIf="workingHours.at(i).get('thursday')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('thursday')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale="de" showSpinButton="false"
																					formControlName="friday"></ejs-numerictextbox>
											<div *ngIf="workingHours.at(i).get('friday')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('friday')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale="de" showSpinButton="false"
																					formControlName="saturday"></ejs-numerictextbox>
											<div *ngIf="workingHours.at(i).get('saturday')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('saturday')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="field">
											<ejs-numerictextbox locale="de" showSpinButton="false"
																					formControlName="sunday"></ejs-numerictextbox>
											<div *ngIf="workingHours.at(i).get('sunday')?.errors as errors">
												<div *ngIf="errors['required'] && workingHours.at(i).get('sunday')?.touched" class="e-error">
													Dieses Feld ist erforderlich.
												</div>
											</div>
										</div>
									</td>
									<td (click)="removeWorkingHoursForm(i)">
										<span role="button" class="material-symbols-outlined icon">close</span>
									</td>
								</tr>
							</table>
						</div>
					</ng-container>

					<!-- Mobile Version -->
					<ng-container *ngIf="currentSectionIndex === 2 && isMobile()" formArrayName="workingHours">
						<div class="vacation-entitlement-container-mobile">
							<button ejs-button [isPrimary]="true" (click)="pushWorkingHoursForm()">Neue Arbeitszeit</button>
							<div class="mobile-fields" *ngFor="let vacations of workingHours.controls; let i = index" [formGroupName]="i">
								<div class="field">
									<label>ZEITRAUM</label>
									<ejs-daterangepicker [firstDayOfWeek]="1" locale="de" showSpinButton="false" formControlName="dateRange"></ejs-daterangepicker>
									<div *ngIf="workingHours.at(i).get('dateRange')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('dateRange')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
										<div *ngIf="errors['coincidingRange'] && vacationEntitlement.at(i).get('dateRange')?.touched" class="e-error">Dieser Datumsbereich sollte nicht mit dem vorherigen Datumsbereich übereinstimmen.</div>
									</div>
								</div>
								<div class="field">
									<label>MO</label>
									<ejs-numerictextbox locale="de" showSpinButton="false" formControlName="monday"></ejs-numerictextbox>
									<div *ngIf="workingHours.at(i).get('monday')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('monday')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
									</div>
								</div>
								<div class="field">
									<label>DI</label>
									<ejs-numerictextbox locale="de" showSpinButton="false" formControlName="tuesday"></ejs-numerictextbox>
									<div *ngIf="workingHours.at(i).get('tuesday')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('tuesday')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
									</div>
								</div>
								<div class="field">
									<label>MI</label>
									<ejs-numerictextbox locale="de" showSpinButton="false" formControlName="wednesday"></ejs-numerictextbox>
									<div *ngIf="workingHours.at(i).get('wednesday')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('wednesday')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
									</div>
								</div>
								<div class="field">
									<label>DO</label>
									<ejs-numerictextbox locale="de" showSpinButton="false" formControlName="thursday"></ejs-numerictextbox>
									<div *ngIf="workingHours.at(i).get('thursday')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('thursday')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
									</div>
								</div>
								<div class="field">
									<label>FR</label>
									<ejs-numerictextbox locale="de" showSpinButton="false" formControlName="friday"></ejs-numerictextbox>
									<div *ngIf="workingHours.at(i).get('friday')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('friday')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
									</div>
								</div>
								<div class="field">
									<label>SA</label>
									<ejs-numerictextbox locale="de" showSpinButton="false" formControlName="saturday"></ejs-numerictextbox>
									<div *ngIf="workingHours.at(i).get('saturday')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('saturday')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
									</div>
								</div>
								<div class="field">
									<label>SO</label>
									<ejs-numerictextbox locale="de" showSpinButton="false" formControlName="sunday"></ejs-numerictextbox>
									<div *ngIf="workingHours.at(i).get('sunday')?.errors as errors">
										<div *ngIf="errors['required'] && workingHours.at(i).get('sunday')?.touched" class="e-error">Dieses Feld ist erforderlich.</div>
									</div>
								</div>
								<div (click)="removeWorkingHoursForm(i)" class="field">
									<span role="button" class="material-symbols-outlined icon">close</span>
								</div>
							</div>
						</div>
					</ng-container>



				</form>
			</div>
		</div>
		<div slot='footer' class="footer-dialog" style="display: flex">
			<button style="margin-left: auto" ejs-button (click)="onCloseDialog()">Abbrechen</button>
			<button style="margin-left: 5px" [isPrimary]="true" [disabled]="employeeForm.invalid" ejs-button
							(click)="onSave()">Speichern
			</button>
		</div>
	</ng-template>
</ejs-dialog>
