import { Injectable } from '@angular/core';

import { TargetVacationDays } from '../models/target-vacation-days.model';

@Injectable({
  providedIn: 'root'
})
export class TargetVacationDaysesCacheService {
  public targetvacationdayses: TargetVacationDays[] = [];
	targetVacationDays: any;

  public insertOrUpdateMany(targetvacationdayses: TargetVacationDays[]): void {
    for (const targetvacationdays of targetvacationdayses) {
      this.insertOrUpdateOne(targetvacationdays);
    }
  }

  public insertOrUpdateOne(targetvacationdays: TargetVacationDays): void {
    const index = this.targetvacationdayses.findIndex(t => t.id === targetvacationdays.id);

    if (index === -1) {
      this.targetvacationdayses.push(targetvacationdays);
    } else {
      this.targetvacationdayses[index] = targetvacationdays;
    }
  }

  public deleteMany(targetvacationdayses: TargetVacationDays[]): void {
    for (const targetvacationdays of targetvacationdayses) {
      this.deleteOne(targetvacationdays);
    }
  }

  public deleteOne(targetvacationdays: TargetVacationDays): void {
    const index = this.targetvacationdayses.findIndex(t => t.id === targetvacationdays.id);

    if (index === -1) {
      return;
    }

    this.targetvacationdayses.splice(index, 1);
  }
}
