import {AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit, ViewChild} from '@angular/core'
import {
	ActionEventArgs,
	EventSettingsModel,
	PopupOpenEventArgs,
	ScheduleComponent
} from '@syncfusion/ej2-angular-schedule'
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Abscence} from 'src/app/entities/abscences/models/abscence.model'
import {ProjectDataCacheService} from 'src/app/project-data-cache.service'
import {DataService} from 'src/app/shared/services/data.service'
import {InteractionsService} from 'src/app/shared/services/interactions.service';
import {Employee} from "../../entities/employees/models/employee.model";
import {AuthService} from 'src/app/shared/services/auth.service';
import {KeycloakService} from "keycloak-angular";
import {HolidaysHttpRequestService} from "../../entities/holidays/services/holidays-http-request.service";
import {SharedService} from "../../shared/services/shared.service";
import moment from 'moment';
import 'moment/locale/de';
import {GermanStateService} from "../../shared/services/german-state.service";
import {ToastModel} from "@syncfusion/ej2-angular-notifications";
import {ToastUtility} from "@syncfusion/ej2-notifications";
import { TimeService } from 'src/app/shared/services/time.service';

@Component({
	selector: 'app-abwesenheitsuebersicht',
	templateUrl: './abwesenheitsuebersicht.component.html',
	styleUrls: ['./abwesenheitsuebersicht.component.scss'],
})
export class AbwesenheitsuebersichtComponent implements AfterViewInit, OnInit {

	@Input()
	public prefix: string = '';
	destroyRef = inject(DestroyRef);
	abscences: any;
	lastYearAbscences: any = 0;
	plannedAbscences: any = 0;
	eventSettings: EventSettingsModel = {};
	currentEmployee: Employee
	currentEmployeeTargetVacationDays: any
	selectedAbscence: Abscence;
	publicHolidaysCount: any;
	publicHolidays: any;
	tempOvertime: any
	public currentYear: number = new Date().getFullYear();

	public constructor(
		public readonly projectDataCacheService: ProjectDataCacheService,
		public readonly interactionsService: InteractionsService,
		public readonly dataService: DataService,
		public readonly authService: AuthService,
		public readonly angularKeycloak: KeycloakService,
		public readonly holidaysHttp: HolidaysHttpRequestService,
		private sharedService: SharedService,
		private germanStateService: GermanStateService,
		private cdr: ChangeDetectorRef,
		private readonly timeService: TimeService
	) {
		this.eventSettings = {

			dataSource: this.dataService.abscencesCacheService?.abscences,
			allowEditing: true,
			allowAdding: true,
			allowDeleting: true,
			editFollowingEvents: true,
			// Mapper to our fields
			fields: {
				id: 'id',
				subject: {name: 'type'},
				description: {name: 'comment'},
				startTime: {name: 'from'},
				endTime: {name: 'to'},

			}
		};

		this.updateCurrentEmployee();

	}

	@ViewChild('scheduleObj') public scheduleObj!: ScheduleComponent

	public ngAfterViewInit(): void {


		if (this.dataService.abscencesCacheService.abscences?.length > 0) this.updateScheduler();

		this.interactionsService.abscencesService.updated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.updateScheduler()
		})
	}

	async ngOnInit() {
		this.sharedService.changeComponentName('Abwesenheitsübersicht');
		this.dataService.employeesService.findAll();
		this.dataService.abscencesService.findAll();

		if (this.currentEmployee) {
			this.addPublicHolidays();
		}
		this.cdr.detectChanges();
	}

	updateCurrentEmployee() {
		if (!this.currentEmployee) {
			this.currentEmployee = this.authService.selectedEmployee;
			this.currentEmployeeTargetVacationDays = this.currentEmployee && this.currentEmployee.targetVacationDayses && this.currentEmployee.targetVacationDayses[0]
			// Handle potential absence data being falsy (e.g., null or undefined)
			const absences = this.currentEmployee?.abscences ? this.currentEmployee.abscences : [];

			if (this.scheduleObj) {
				// Combine absences with Feiertags (if any)
				this.abscences = this.publicHolidays ? absences.concat(this.publicHolidays) : absences;
				this.updateSchedulerWithAbscences(this.abscences);
			}
		}

		// Subscribe to current user observable to get initial employee data
		this.authService.selectedUser$.subscribe((employee) => {
			this.currentEmployee = employee;

			// Handle potential absence data being falsy (e.g., null or undefined)
			const absences = employee?.abscences ? employee.abscences : [];

			// Set target vacation day if available
			if (employee?.targetVacationDayses && employee?.targetVacationDayses?.length > 0) {
				this.currentEmployeeTargetVacationDays = employee.targetVacationDayses[0];
			}
			if (this.scheduleObj) {
				// Combine absences with Feiertags (if any)
				this.abscences = this.publicHolidays ? absences.concat(this.publicHolidays) : absences;
				if (this.currentEmployee) {
					this.addPublicHolidays();
				}
				this.updateSchedulerWithAbscences(this.abscences);
			}

		});

		this.dataService.abscencesService.updated$.subscribe(() => {
			// Filter absences for current employee (if any)
			const absences = this.dataService.abscencesCacheService.abscences
				.filter((abscence) => abscence.employee?.id === this.currentEmployee?.id);

			// Update scheduler with combined absences and Feiertags (or empty array)
			this.updateSchedulerWithAbscences(absences.concat(this.publicHolidays || []));
			this.abscences = absences.concat(this.publicHolidays || []);
			this.dataService.employeesService.findAll();
		});
	}

	addPublicHolidays() {
		this.holidaysHttp.findPublicHolidays().subscribe((holidays) => {
			const stateToApply = this.currentEmployee.stateToApply;
			let stateHolidays = holidays.NATIONAL;

			if (stateToApply) {
				const stateShortForm = this.germanStateService.getShortForm(stateToApply);
				if (stateShortForm && holidays[stateShortForm]) {
					stateHolidays = holidays[stateShortForm];
				}
			}

			this.publicHolidays = Object.entries(stateHolidays).map(([holidayName, holidayDetails]: [string, any]) => {
				return {
					type: 'Feiertag',
					name: holidayName, // This is the holiday name
					from: new Date(holidayDetails.datum),
					to: new Date(holidayDetails.datum),
					comment: holidayDetails.hinweis,
					halfDay: false,
				};
			});

			if (Array.isArray(this.scheduleObj?.eventSettings?.dataSource)) {
				let absencesWithNames = this.abscences.filter(item => item.hasOwnProperty('name'));
				let uniquePublicHolidays = [...new Map(absencesWithNames.concat(this.publicHolidays).map(item => [item['name'], item])).values()];
				let absencesWithoutNames = this.abscences.filter(item => !item.hasOwnProperty('name'));
				this.publicHolidaysCount = uniquePublicHolidays.length;
				let absences = absencesWithoutNames.concat(uniquePublicHolidays);
				this.updateSchedulerWithAbscences(absences);
			}
		});
	}


	filterAbsencesByYear(absences: Abscence[]): Abscence[] {
		return absences.filter((abscence: Abscence) => {
			return abscence.from.getFullYear() == this.currentYear;
		});
	}

	updateSchedulerWithAbscences(abscences: Abscence[]): void {
		if (this.scheduleObj.eventSettings && abscences && abscences.length > 0) {
			const events = abscences.map(event => {
				return {
					...event,
					to: new Date(new Date(event.to).getTime() + 1000)
				};
			});

			this.scheduleObj.eventSettings.dataSource = events;
			this.scheduleObj.eventSettings.allowEditing = true;
			this.scheduleObj.eventSettings.allowAdding = true;
			this.scheduleObj.eventSettings.allowDeleting = true;
			// this.scheduleObj.refreshEvents();
			setTimeout(() => this.colorDots(abscences), 500);
		}
	}

	updateScheduler(): void {
		// Check if this.scheduleObj, this.scheduleObj.eventSettings, this.scheduleObj.eventSettings.dataSource, and this.abscences are not null or undefined
		if (this.scheduleObj && this.scheduleObj.eventSettings && this.scheduleObj.eventSettings.dataSource && this.abscences) {
			let absences = this.abscences;
			let sch = this.scheduleObj.eventSettings.dataSource;

			// Check if absences is not empty and is the same as the dataSource
			if (absences.length > 0 && this.isSameDataSource(absences, sch)) {
				let dotIsThere = this.checkIfDotIsThere(absences[0]);
				if (dotIsThere) {
					this.colorDots(absences);
				} else {
					// Check if absences[0] is not null or undefined before using it
					if (absences[0]) {
						this.waitForDot(absences[0].from.toLocaleDateString('de-DE', {
							weekday: 'long',
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						}));
					}
				}
			}
		}
	}

	public updateOvertime(): void {
		this.timeService.selectedDate = new Date();
		this.timeService.currentEmployee = this.currentEmployee;
		this.timeService.loadTimeEntries();
		this.timeService.calculateTargetWorkingHours(this.currentEmployee);
		this.timeService.calculateValues(this.timeService.timeEntries);
		this.tempOvertime = this.timeService.userOvertime;
	}

	isSameDataSource(array1, array2): boolean {
		if (array1.length !== array2.length) {
			return false;
		}

		for (let i = 0; i < array1.length; i++) {
			const obj1 = array1[i];
			const obj2 = array2[i];

			const keys1 = Object.keys(obj1);
			const keys2 = Object.keys(obj2);

			if (keys1.length !== keys2.length) {
				return false;
			}

			for (const key of keys1) {
				if (obj1[key] !== obj2[key]) {
					return false;
				}
			}
		}

		return true;
	}

	waitCounter = 0

	waitForDot(datumText: string) {
		const maxAttempts = 20;
		let attempts = 0;

		const checkDot = () => {
			attempts++;
			const spanElement = document.querySelector(`span[title="${datumText}"]`);
			if (spanElement || attempts >= maxAttempts) {
				if (spanElement) {
					this.colorDots(this.dataService.abscencesCacheService.abscences);
				}
			} else {
				setTimeout(checkDot, 50);
			}
		};

		checkDot();
	}

	colorEvent(event: any) {
		if (this.abscences.length > 0) {
			let spanElement = event.element.querySelector('span');
			let title = spanElement.getAttribute('title');
			// let titleDate = new Date(title);
			const titleDate = moment(title, 'dddd, DD. MMMM YYYY', 'de').toDate();

			let options: Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

			let index = this.abscences.concat(this.publicHolidays).findIndex(abscence => {
				let abscenceDateStr = abscence.from.toLocaleDateString('de-DE', options);
				let abscenceDate = moment(abscenceDateStr, 'dddd, DD. MMMM YYYY', 'de').toDate();
				return abscenceDate.getTime() === titleDate.getTime();
			});

			setTimeout(() => {
				let appointmentDivs = document.querySelectorAll('.e-more-appointment-wrapper > .e-appointment');
				let absences = this.abscences.concat(this.publicHolidays);
				appointmentDivs.forEach(div => {
					switch (absences[index].type) {
						case 'Krankheit':
							div.classList.add('type-krankheit');
							break;
						case 'Bezahlter Urlaub':
							div.classList.add('type-urlaub');
							break;
						case 'Feiertag':
							(div as HTMLElement).style.padding = '2px 0 2px 7px'
							div.innerHTML = absences[index].name;
							div.classList.add('type-feiertag');
							break;
						case 'Elternzeit':
							div.classList.add('type-elternzeit');
							break;
					}
				});
			}, 100);
		}
	}

	colorDots(absences: Abscence[]) {

		let options: Intl.DateTimeFormatOptions = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};
		absences.forEach((abscence: Abscence) => {
			let dates = getDatesBetween(abscence.from, abscence.to);
			dates.forEach((date: Date) => {
				let datumString = date.toLocaleDateString('de-DE', options);
				document.querySelectorAll('.e-appointment').forEach(appointmentDiv => {
					let siblingSpan = appointmentDiv.previousElementSibling;
					if (siblingSpan && siblingSpan.getAttribute('title') === datumString) {
						switch (abscence.type) {
							case 'Krankheit':
								appointmentDiv.classList.add('type-krankheit');
								break;
							case 'Bezahlter Urlaub':
								appointmentDiv.classList.add('type-urlaub');
								break;
							case 'Feiertag':
								appointmentDiv.classList.add('type-feiertag');
								break;
							case 'Elternzeit':
								appointmentDiv.classList.add('type-elternzeit');
								break;
						}
					}
				});
			});
		});
	}

	onActionComplete(args: ActionEventArgs): void {
		if (args.requestType === 'eventCreate') {
			this.colorDots(this.dataService.abscencesCacheService.abscences);
		}
		if (args.requestType === 'eventChanged') {
			this.showAbwesenheitDialog = true;
			this.selectedAbscence = args.data as Abscence;
			this.colorDots(this.dataService.abscencesCacheService.abscences);
		}
		if (args.requestType === 'eventRemoved') {
			// Call the delete function when the delete button is clicked
			if (args?.data) {
				let abscence = args.data[0];
				this.interactionsService.abscencesService.delete(abscence as Abscence);

				this.updateScheduler();
				this.updateOvertime();
			}
		}
	}


	onPopupOpen(args: PopupOpenEventArgs): void {
		args.cancel = this.authService.currentEmployee.role !== 'admin';

		// Check if the popup is the event editor.
		if (args.type === 'Editor') {
			// Prevent the default event editor from popping up.
			args.cancel = true;

			// Open your custom dialog.
			this.showAbwesenheitDialog = true;
			this.selectedAbscence = args.data as Abscence;
		}
		if (args.type === 'QuickInfo') {
			// Change the text of the edit and delete buttons
			let editButton: HTMLElement = args.element.querySelector('.e-event-edit') as HTMLElement;
			let deleteButton: HTMLElement = args.element.querySelector('.e-event-delete') as HTMLElement;
			if (editButton) {
				editButton.innerText = 'Bearbeiten';
			}
			if (deleteButton) {
				deleteButton.innerText = 'Löschen';
			}
		}
		if (args.type === 'DeleteAlert') {
			// Change the text of the edit and delete buttons
			let noButton: HTMLElement = args.element.querySelector('.e-quick-dialog-cancel') as HTMLElement;
			let yesButton: HTMLElement = args.element.querySelector('.e-quick-dialog-delete') as HTMLElement;
			let dialog: HTMLElement = args.element.querySelector('.e-dlg-content') as HTMLElement;
			let header: HTMLElement = args.element.querySelector('.e-dlg-header') as HTMLElement;
			if (yesButton) {
				yesButton.innerText = 'Löschen';
			}
			if (noButton) {
				noButton.innerText = 'Abbrechen';
			}
			if (dialog) {
				dialog.innerText = 'Sind Sie sicher, dass Sie diese Abwesenheit löschen möchten??';
			}
			if (header) {
				header.innerText = 'Die Abwesenheit löschen';
			}
		}
	}

	getAppointmentElement(spanElement: Element): Element {
		let appointmentDiv = spanElement.parentElement?.querySelector('.e-appointment');
		return appointmentDiv || spanElement;
	}


	checkIfDotIsThere(abscence: Abscence) {

		let options: Intl.DateTimeFormatOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}
		let datumString = abscence.from.toLocaleDateString('de-DE', options)

		// Span element next to the dot element
		let spanElement = document.querySelector('span[title="' + datumString + '"]')

		let appointmentDiv: Element
		if (!spanElement) return false
		else appointmentDiv = this.getAppointmentElement(spanElement)

		return !!appointmentDiv
	}

	isCurrentEmployeeSelected(): boolean {
		return this.currentEmployee && this.authService.currentEmployee?.id === this.currentEmployee?.id;
	}


	showAbwesenheitDialog = false
	showConfirmationDialog = false

	public onButtonClicked(): void {
		if (!this.isCurrentEmployeeSelected() && this.authService.currentEmployee.role !== 'admin') {
			let toast: ToastModel = ToastUtility.show('Sie können keine Abwesenheiten für andere Benutzer hinzufügen.', 'Error', 5000);
			return;
		}

		this.showAbwesenheitDialog = true;
	}

	public fields = {text: 'text', value: 'value'};


	public firstMonthOfYear(args: Record<string, number>): void {
		this.scheduleObj.firstMonthOfYear = args['value'];
	}

	public numberOfMonths(args: Record<string, number>): void {
		this.scheduleObj.monthsCount = args['value'];
	}

	newLeaveRequest: Partial<Abscence> = {}

	public handleNext(data: any) {
		const {newLeaveRequest, currentEmployee} = data;
		this.updateOvertime();

		if (newLeaveRequest.from) {
			newLeaveRequest.from = new Date(Date.UTC(
				newLeaveRequest.from.getFullYear(),
				newLeaveRequest.from.getMonth(),
				newLeaveRequest.from.getDate(),
				0, 0, 0, 0
			));
		}

		if (newLeaveRequest.to) {
			newLeaveRequest.to = new Date(Date.UTC(
				newLeaveRequest.to.getFullYear(),
				newLeaveRequest.to.getMonth(),
				newLeaveRequest.to.getDate(),
				0, 0, 0, 0
			));
		}

		newLeaveRequest.employee = currentEmployee;
		this.showAbwesenheitDialog = false;
		this.showConfirmationDialog = true;

		this.newLeaveRequest = newLeaveRequest;
	}


	public handleSend() {

		this.showConfirmationDialog = false;
		const startDate = this.newLeaveRequest.from || new Date();
		const endDate = this.newLeaveRequest.to || new Date();

		// If the absence is being edited, delete the existing absence
		if (this.selectedAbscence) {
			this.interactionsService.abscencesService.delete(this.selectedAbscence);
		}

		// Create a new leave entry for each day between the start and end dates
		// for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
			// Create a copy of the newLeaveRequest object
			let dailyLeaveRequest = {...this.newLeaveRequest};

			// Set the from and to dates of the daily leave request to the current date
			// dailyLeaveRequest.from = new Date(d);
			// dailyLeaveRequest.to = new Date(d);

			// Create the daily leave request
			// this.dataService.employeesService.update(this.currentEmployee);
			this.interactionsService.abscencesService.create({...dailyLeaveRequest, substitutionStatus:'requested'});
		// }
		setTimeout(() => {
			this.updateOvertime();
		}, 2000)
		this.dataService.reloadAbsences();

	}

	calculateTotalTakenAbsenceDays(): number {
		if (this.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = this.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from <= currentDate;
			})

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				const holidaysCount = this.getHolidaysBetween(fromDate, toDate);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				diffInDays -= holidaysCount;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}
	}

	getHolidaysBetween(fromDate: Date, toDate: Date): number {
		let holidaysCount = 0;
		let currentDate = new Date(fromDate);
		currentDate.setHours(1);
		while (currentDate <= toDate) {
			const isHoliday = this.publicHolidays?.find((holiday: any) => {
				return holiday.from.toDateString() === currentDate.toDateString();
			});
			if (isHoliday) {
				holidaysCount++;
			}
			currentDate.setDate(currentDate.getDate() + 1);
		}
		return holidaysCount;
	}

	calculateTotalPlannedAbsenceDays(): number {
		if (this.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = this.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from > currentDate;
			})

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}

	}

	calculateRemainingVacationDays(): string {
		let remaining = 0;
		let totalTakenAbsenceDays = this.calculateTotalTakenAbsenceDays();
		this.plannedAbscences = this.calculateTotalPlannedAbsenceDays();
		remaining = this.currentEmployeeTargetVacationDays?.amount - totalTakenAbsenceDays + this.lastYearAbscences - this.plannedAbscences
		return this.convertDecimalToDaysAndHours(remaining);
	}

	convertDecimalToDaysAndHours(decimal: number): string {
		const days = Math.floor(decimal);
		const hours = Math.round((decimal - days) * 8);

		return `${days} Tage und ${hours} Stunden`;
	}

	calculateAbscenceCounts(): any {
		if (this.abscences) {
			let filteredAbsences = this.filterAbsencesByYear(this.abscences);
			const totalElternzeit = filteredAbsences.filter((a: any) => a.type === 'Elternzeit').length;
			const totalKrankheit = filteredAbsences.filter((a: any) => a.type === 'Krankheit').length;
			const totalBezahlterUrlaub = filteredAbsences.filter((a: any) => a.type === 'Bezahlter Urlaub').length;

			return {totalElternzeit, totalKrankheit, totalBezahlterUrlaub};
		} else {
			return {totalElternzeit: 0, totalKrankheit: 0, totalBezahlterUrlaub: 0};
		}
	}

	calculateLastYearAbscences(): number {
		if (this.abscences) {
			let filteredAbsences = this.abscences.filter((ab: Abscence) => {
				return ab.type !== 'Feiertag';
			})

			if (filteredAbsences) {
				const lastYear = this.currentYear - 1;
				const lastYearAbsences = filteredAbsences.filter((a: any) => a.from.getFullYear() === lastYear);
				this.lastYearAbscences = lastYearAbsences.length;
			}
		}
		return this.lastYearAbscences;

	}


	onNavigated($event: any) {
		this.currentYear = $event.currentDate.getFullYear();
		this.updateSchedulerWithAbscences(this.abscences);
	}

	protected readonly Object = Object;
}


function getDatesBetween(startDate: Date, endDate: Date): Date[] {
	let dates: Date[] = [];

	let currentDate = new Date(startDate);
	let adjustedEndDate = new Date(endDate);

	while (currentDate <= adjustedEndDate) {
		dates.push(new Date(currentDate));
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
}
