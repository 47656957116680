import {Component, DestroyRef, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Employee} from 'src/app/entities/employees/models/employee.model';
import {DataService} from 'src/app/shared/services/data.service';
import {Dialog} from '@syncfusion/ej2-popups';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TargetVacationDays} from 'src/app/entities/target-vacation-dayses/models/target-vacation-days.model';
import {TargetWorkingHours} from 'src/app/entities/target-working-hourses/models/target-working-hours.model';
import {concatMap, from, tap} from 'rxjs';
import {InteractionsService} from "../../shared/services/interactions.service";
import * as XLSX from "xlsx";
import {HolidaysHttpRequestService} from "../../entities/holidays/services/holidays-http-request.service";
import {SharedService} from "../../shared/services/shared.service";
import moment from "moment";
import {GridComponent, GridModel} from "@syncfusion/ej2-angular-grids";
import {Abscence} from "../../entities/abscences/models/abscence.model";
import { v4 as uuid } from 'uuid'
import {isMobile} from "@syncfusion/ej2-angular-schedule";
import {ToastModel} from "@syncfusion/ej2-angular-notifications";
import {ToastUtility} from "@syncfusion/ej2-notifications";
interface VacationEntitlement {
	id: string;
	amount: number;
	dateRange: [Date, Date];
}

interface WorkingHours {
	id: string;
	dateRange: [Date, Date];
	monday: number;
	tuesday: number;
	wednesday: number;
	thursday: number;
	friday: number;
	saturday: number;
	sunday: number;
}

enum roleTypes {
	Mitarbeiter = 'employee',
	Verwaltung = 'admin',
	Admin = 'Verwaltung',
	Employee = 'Mitarbeiter',
	admin = 'Verwaltung',
	employee = 'Mitarbeiter',
	user = 'employee',
	User = 'employee'
}

@Component({
	selector: 'app-employees-overview-page',
	templateUrl: './employees-overview-page.component.html',
	styleUrl: './employees-overview-page.component.scss'
})
export class EmployeesOverviewPageComponent implements OnInit {
	@ViewChild('dialog') public dialog: Dialog;
	@ViewChild('#mainTable') public mainTable: GridComponent;
	public dataSource: Array<Employee> = [];
	public archivedEmployees: Array<Employee> = [];
	public employeeForm: FormGroup;
	public dialogVisible: boolean = false;
	public isEdit: boolean = false;
	public position = {X: 650, Y: 240};
	public currentSectionIndex = 0;
	sectionName = 'Mitarbeiter Personaldaten';
	lastYearAbscences: any;
	plannedAbscences: any;
	targetVacationDays: any;
	currentEmployee: any;
	private fileData: any;
	germanStates: any
	public currentYear: number = new Date().getFullYear();
	outlets: string[] = [
		'Kassel',
		'Eschwege',
		'Eisenach',
		'Bad Arolsen',
		'Osnabrück',
		'Delmenhorst',
		'Friedrichshafen',
		'Unna',
		'Rheine',
		'Cloppenburg',
	]

	roles: any = [
		{label: 'Mitarbeiter', value: 'employee'},
		{label: 'Verwaltung', value: 'admin'}
	];


	get vacationEntitlement() {
		return (this.employeeForm?.controls['vacationEntitlement'] as FormArray)
	}

	get workingHours() {
		return (this.employeeForm?.controls['workingHours'] as FormArray)
	}

	get employeeForm_() {
		return this.employeeForm?.controls['employee']
	}

	constructor(private readonly dataService: DataService, private readonly destroyRef: DestroyRef, private readonly fb: FormBuilder,
							private readonly interactionsService: InteractionsService,
							private holidaysHttpService: HolidaysHttpRequestService,
							private sharedService: SharedService) {
	}

	ngOnInit(): void {
		this.sharedService.changeComponentName('Mitarbeiterübersicht');
		this.initEmployees();
		this.initForm();
		this.holidaysHttpService.findGermanStates().subscribe((states: []) => {
			this.germanStates = states.map((state: any) => {
				return state.name
			});
			console.log("STATES", this.germanStates)
		})
		this.dataService.abscencesService.findAll();
	}
	initEmployees() {
		this.dataService.employeesService.employees$.subscribe(() => {
			var employees = this.dataService.employeesCacheService.employees;
			console.log("employees$ EMP", this.dataService.employeesCacheService.employees)

			if (employees.length > 0) {
				employees.forEach(employee => {
					// employee.remainingVacationDays = this.calculateRemainingVacationDays(employee);
					if (employee.targetVacationDayses && employee.targetVacationDayses.length > 0) {
						employee.remainingVacationDays = this.calculateRemainingVacationDays(employee)
					}
				});
				console.log("EMPLOYEE OVERVIEW PAGE2", employees)
				this.dataSource = employees.filter(employee => !employee.isArchived);
				this.archivedEmployees = employees.filter(employee => employee.isArchived);
				if (this.mainTable) {
					this.mainTable.refresh();
				}
			}
		})

		this.dataService.employeesService.updated$.subscribe((emp) => {
			console.log("updated$ EMP", this.dataService.employeesCacheService.employees)
			let emps = this.dataService.employeesCacheService.employees;
			this.refreshTable(emps);
		})
	}
	refreshTable(employees) {
		this.dataSource = employees.filter(employee => !employee.isArchived);
		this.archivedEmployees = employees.filter(employee => employee.isArchived);
		if (this.mainTable) {
			this.mainTable.refresh();
		}
	}

	calculateRemainingVacationDays(employee: Employee): string {
		let remaining = 0;
		let totalTakenAbsenceDays = this.calculateTotalTakenAbsenceDays(employee);
		this.plannedAbscences = this.calculateTotalPlannedAbsenceDays(employee);
		this.lastYearAbscences = this.calculateLastYearAbscences(employee);
		remaining = employee.targetVacationDayses? employee.targetVacationDayses[0].amount - totalTakenAbsenceDays + this.lastYearAbscences - this.plannedAbscences : 0;
		return this.convertDecimalToDaysAndHours(remaining);
	}

	calculateLastYearAbscences(employee: Employee): number {
		if (employee.abscences) {
			let filteredAbsences = employee.abscences.filter((ab: Abscence) => {
				return ab.type !== 'Feiertag';
			})

			if (filteredAbsences) {
				const lastYear = this.currentYear - 1;
				const lastYearAbsences = filteredAbsences.filter((a: any) => a.from.getFullYear() === lastYear);
				this.lastYearAbscences = lastYearAbsences.length;
			}
		}
		return this.lastYearAbscences;

	}
	calculateTotalPlannedAbsenceDays(employee): number {
		if (employee.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = employee.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from > currentDate;
			})

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}
	}

	calculateTotalTakenAbsenceDays(employee): number {
		if (employee.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = employee.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from <= currentDate;
			})

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}
	}

	convertDecimalToDaysAndHours(decimal: number): string {
		const days = Math.floor(decimal);
		const hours = Math.round((decimal - days) * 8);

		return `${days} Tage und ${hours} Stunden`;
	}

	calculateTotalAbsenceDays(): number {
		let totalDays = 0;

		for (let absence of this.currentEmployee.abscences) {
			// Create date objects from the absence 'from' and 'to' dates
			let fromDate = new Date(absence.from);
			let toDate = new Date(absence.to);

			// Calculate the difference in time between the two dates
			let diffInTime = toDate.getTime() - fromDate.getTime();

			// Calculate the difference in days and add 1 to include the start day
			let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

			// Add the difference in days to the total
			totalDays += diffInDays;
		}
		return totalDays;
	}

	initForm(): void {
		this.employeeForm = this.fb.group({
			employee: this.fb.group({
				id: [''],
				firstname: ['', Validators.required],
				lastname: ['', Validators.required],
				role: ['', Validators.required],
				outlet: ['', Validators.required],
				// overtime: [''],
				email: ['', [Validators.required, Validators.email]],
				birthday: ['', Validators.required],
				startDate: ['', Validators.required],
				stateToApply: ['', Validators.required],
			}),
			vacationEntitlement: this.fb.array([]),
			workingHours: this.fb.array([])

		});
	}

	public onAddClick(): void {
		this.isEdit = false;
		this.employeeForm.reset();
		this.dialogVisible = true;
	}

	public onEditClick(employee: Employee): void {
		this.isEdit = true;
		const vacationEntitlement: VacationEntitlement[] = employee.targetVacationDayses ? employee.targetVacationDayses
			.sort((a, b) => a.from.getTime() - b.from.getTime())
			.map(vacationday => {
				return {
					amount: vacationday.amount,
					dateRange: [vacationday.from, vacationday.to],
					id: vacationday.id
				}
			}) : [];

		vacationEntitlement.forEach(ve => {
			this.pushVacationEntitlementForm()
		})

		const workingHours: WorkingHours[] = employee.targetWorkingHourses ? employee.targetWorkingHourses
			.sort((a, b) => a.from.getTime() - b.from.getTime())
			.map((working) => {
				return {
					id: working.id,
					dateRange: [working.from, working.to],
					monday: working.monday,
					tuesday: working.tuesday,
					wednesday: working.wednesday,
					thursday: working.thursday,
					friday: working.friday,
					saturday: working.saturday,
					sunday: working.sunday,
				}
			}) : [];


		workingHours.forEach(wh => this.pushWorkingHoursForm())

		this.employeeForm.patchValue({
			employee: {
				id: employee.id,
				firstname: employee.firstname,
				lastname: employee.lastname,
				role: employee.role,
				// overtime: employee.overtime,
				email: employee.email,
				isArchived: employee.isArchived,
				birthday: employee.birthday,
				startDate: employee.startDate,
				outlet: employee.outlet,
				stateToApply: employee.stateToApply,
			},
			vacationEntitlement: vacationEntitlement,
			workingHours: workingHours

		});
		this.dialogVisible = true;

		console.log(this.employeeForm)
	}

	public onSave(): void {

		const vacationEntitlement = this.employeeForm.controls['vacationEntitlement'].value as VacationEntitlement[];
		const workingHours = this.employeeForm.controls['workingHours'].value as WorkingHours[];
		const employeeID = this.employeeForm.controls['employee'].value.id;
		const abscences = employeeID ?
			this.dataService.abscencesCacheService.abscences
				.filter(abscence => abscence.employee && abscence.employee.id === employeeID) as Abscence[] : [];

		const targetVacationDays: TargetVacationDays[] = vacationEntitlement.map(entitlement => {
			return {
				id: entitlement.id,
				amount: Number(entitlement.amount),
				from: entitlement.dateRange[0],
				to: entitlement.dateRange[1],
				employee: this.employeeForm.value.employee
			}
		});

		const targetWorkingHours: TargetWorkingHours[] = workingHours.map(workingHours => {
			return {
				id: workingHours.id,
				from: workingHours.dateRange[0],
				to: workingHours.dateRange[1],
				monday: Number(workingHours.monday),
				tuesday: Number(workingHours.tuesday),
				wednesday: Number(workingHours.wednesday),
				thursday: Number(workingHours.thursday),
				friday: Number(workingHours.friday),
				saturday: Number(workingHours.saturday),
				sunday: Number(workingHours.sunday),
				employee: this.employeeForm.value.employee
			}
		});

		if (this.isEdit) {

			this.dataService.employeesService.update(this.employeeForm.value.employee);

			targetVacationDays.forEach(targetVacation => {
				if (targetVacation.id) {
					this.dataService.targetVacationDaysesService.update(targetVacation);
				} else {
					this.dataService.targetVacationDaysesService.create(targetVacation);
				}
			})

			targetWorkingHours.forEach(targetWorking => {
				if (targetWorking.id) {
					this.dataService.targetWorkingHoursesService.update(targetWorking);
				} else {
					this.dataService.targetWorkingHoursesService.create(targetWorking);
				}
			});

			abscences.forEach(abscence => {
				if (abscence.id) {
					this.dataService.abscencesService.update(abscence);
				} else {
					this.dataService.abscencesService.update(abscence);
				}
			});

			this.dataService.reloadEmployees();
			this.dataService.reloadAbsences();
			this.dataService.reloadTargetVacations();
			this.dataService.reloadWorkingHours();
		} else {
			const {id, ...createData} = this.employeeForm.value.employee;
			if (targetWorkingHours.length === 0 || targetVacationDays.length === 0) {
				let toast: ToastModel = ToastUtility.show('Bitte füllen Sie die fehlenden Angaben aus: Soll-Arbeitsstunden und Soll-Urlaubstage.', 'Error', 10000);
				return;
			}
			// createData.role = roleTypes[createData.role];
			createData.isArchived = false;
			createData.birthday = moment(createData.birthday).utc(true).locale('de').startOf('day').toDate();
			createData.startDate = moment(createData.startDate).utc(true).locale('de').startOf('day').toDate();
			this.dataService.employeesHttpService.create(createData)
				.subscribe((emp) => {
					targetVacationDays.forEach(targetVacation => {
						targetVacation.employee = emp;
						this.dataService.targetVacationDaysesService.create(targetVacation);
					})

					targetWorkingHours.forEach(targetWorking => {
						targetWorking.employee = emp;
						this.dataService.targetWorkingHoursesService.create(targetWorking);
					});

					this.dataService.reloadEmployees();
					this.dataService.reloadTargetVacations();
					this.dataService.reloadWorkingHours();
					setTimeout(() => {
						// this.dataService.employeesService.findAll();
					}, 1000);
				});

		}
		this.dialogVisible = false;
		this.resetForm();
	}

	public onCloseDialog(): void {
		this.dialogVisible = false;
		this.resetForm();
		this.currentSectionIndex = 0;
	}

	resetForm() {
		this.currentSectionIndex = 0;
		this.employeeForm.reset();
		this.employeeForm.controls['workingHours'] = this.fb.array([]);
		this.employeeForm.controls['vacationEntitlement'] = this.fb.array([]);
		console.log(this.employeeForm)
	}

	private getWorkingHoursForm(currentIndex: number) {
		return this.fb.group({
			id: [''],
			monday: [0, [Validators.required]],
			tuesday: [0, [Validators.required]],
			wednesday: [0, [Validators.required]],
			thursday: [0, [Validators.required]],
			friday: [0, [Validators.required]],
			saturday: [0, [Validators.required]],
			sunday: [0, [Validators.required]],
			dateRange: [[new Date(), new Date()], [Validators.required, this.dateRangeShouldNotCoincideWithPreviousDateRange(currentIndex, this.workingHours)]],
		})
	}

	private getVacationEntitlementForm(currentIndex: number) {
		return this.fb.group({
			id: [''],
			dateRange: [[new Date(), new Date()], [Validators.required, this.dateRangeShouldNotCoincideWithPreviousDateRange(currentIndex, this.vacationEntitlement)]],
			amount: [0, Validators.required]
		})
	}

	public pushWorkingHoursForm() {
		const length = this.workingHours.length;
		this.workingHours.push(this.getWorkingHoursForm(length))
	}

	public pushVacationEntitlementForm() {
		const length = this.vacationEntitlement.length;
		this.vacationEntitlement.push(this.getVacationEntitlementForm(length))
	}

	public removeWorkingHoursForm(index: number) {
		const workingHours: WorkingHours = this.workingHours.controls[index].value;
		if (workingHours.id) {
			this.dataService.targetWorkingHoursesService.delete({
				id: workingHours.id,
				from: workingHours.dateRange[0],
				to: workingHours.dateRange[1],
				monday: workingHours.monday,
				tuesday: workingHours.tuesday,
				wednesday: workingHours.wednesday,
				thursday: workingHours.thursday,
				friday: workingHours.friday,
				saturday: workingHours.saturday,
				sunday: workingHours.sunday
			})
		}
		this.workingHours.removeAt(index);
	}

	public removeVacationEntitlementForm(index: number) {
		const vacationEntitlement: VacationEntitlement = this.vacationEntitlement.controls[index].value;
		if (vacationEntitlement.id) {
			this.dataService.targetVacationDaysesService.delete({
				id: vacationEntitlement.id,
				amount: vacationEntitlement.amount,
				from: vacationEntitlement.dateRange[0],
				to: vacationEntitlement.dateRange[1]
			});
		}
		this.vacationEntitlement.removeAt(index);
	}

	public dateRangeShouldNotCoincideWithPreviousDateRange(index: number, form: FormArray) {
		return (abstractControl: AbstractControl) => {
			const previousRange = form.at(index - 1)?.get('dateRange')?.value as [Date, Date];
			const currentRange = abstractControl.value as [Date, Date];
			if (!index || !form || !previousRange) {
				return null;
			}
			return previousRange[1].getTime() < currentRange[0].getTime() ? null : {coincidingRange: true}
		}
	}

	public onEmployeeDelete(employee: Employee) {
		// employee.targetVacationDayses?.forEach(tvd => this.dataService.targetVacationDaysesService.delete(tvd));
		// employee.targetWorkingHourses?.forEach(twh => this.dataService.targetWorkingHoursesService.delete(twh));
		employee.isArchived = true;
		this.dataService.employeesService.update(employee);
		this.dataService.reloadTargetVacations();
		this.dataService.reloadWorkingHours();
		this.dataService.reloadEmployees();
	}

	onEmployeeUnarchive(employee: Employee) {
		employee.isArchived = false;
		this.dataService.employeesService.update(employee);
		this.dataService.reloadEmployees();
		this.dataService.reloadTargetVacations();
		this.dataService.reloadWorkingHours();
	}

	onFileChange(evt: any) {
		const target: DataTransfer = <DataTransfer>(evt.target);
		if (target.files.length !== 1) throw new Error('Cannot use multiple files');

		const reader: FileReader = new FileReader();
		reader.onload = async (e: any) => {
			const bstr: string = e.target.result;
			const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
			const wsname: string = wb.SheetNames[0];
			const ws: XLSX.WorkSheet = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws, {header: 1});
			await this.extractData(data).then((data) => {
			});
			this.dataService.employeesService.findAll();
			setTimeout(() => {
				this.addNewTargetVacationDays();
			}, 3000);
		};
		reader.readAsBinaryString(target.files[0]);
	}

	async extractData(data: any[]) {
		// Assuming the first row contains column headers e.g., Name and Email
		this.fileData = data.slice(1).map(row => ({
			firstName: row[0],
			lastName: row[1],
			role: row[2],
			email: row[3],
			birthday: row[4],
			state: row[5],
			targetVacationPerYear: row[6],
			takenThisYear: row[7],
			lastYearVacation: row[8],
			startDate: row[11]
		})).filter(row => row.firstName && row.lastName && row.role && row.email);
		console.log("this.fileData", this.fileData);

		const createPromises = this.fileData.map((user: any) => {
			let userData = {
				firstname: user.firstName,
				lastname: user.lastName,
				email: user.email,
				isArchived: false,
				role: roleTypes[user.role],
				birthday: moment(this.convertExcelDate(user.birthday), 'DD.MM.YYYY').locale('de').utc(true).startOf('day').toDate(),
				outlet: 'Kassel',
				stateToApply: user.state,
				startDate: moment(this.convertExcelDate(user.startDate), 'DD.MM.YYYY').locale('de').utc(true).startOf('day').toDate(),
				// targetVacationDayses: [
				// 	{
				// 		id: uuid(),
				// 		amount: ((user.targetVacationPerYear + user.laslastYearVacation) - user.takenThisYear),
				// 		from: moment().startOf('year').toDate(),
				// 		to: moment().endOf('year').toDate()
				// 	}
				// ],
			}
			return this.dataService.employeesService.create(userData);
		});

		try {
			await Promise.all(createPromises).then(() => {

			});
		}
		catch (error) {
			console.error('Error creating employees:', error);
		}
	}

	convertExcelDate(excelDate: number): Date {
		// Excel-Datumsbasis ist der 30. Dezember 1899
		const excelBaseDate = new Date(1899, 11, 30); // Monat ist nullbasiert, daher 11 für Dezember
		const actualDate = new Date(excelBaseDate.getTime() + excelDate * 24 * 60 * 60 * 1000);
		return actualDate;
	}

	addNewTargetVacationDays() {
		if (this.fileData) {
			console.log("FILE DATA", this.fileData);
			this.fileData.forEach((user: any) => {
				let employees = this.dataService.employeesCacheService.employees;
				let employee = employees.find((emp) => emp.email === user.email);
				if (employee) {
					this.dataService.targetVacationDaysesService.create({
						amount: ((user.targetVacationPerYear + user.lastYearVacation) - user.takenThisYear),
						from: moment().startOf('year').toDate(),
						to: moment().endOf('year').toDate(),
						employee: employee
					})
				}
			})
			this.dataService.targetVacationDaysesService.findAll();
			this.dataService.reloadEmployees();
			setTimeout(() => {
				this.refreshTable(this.dataService.employeesCacheService.employees);
				this.fileData = null;
			}, 2000);
		}
	}

	changeSection(index: number) {
		this.currentSectionIndex = index;
		this.sectionName = index === 0 ? 'Mitarbeiter Personaldaten' : index === 1 ? 'Mitarbeiter Urlaubsanspruch' : 'Mitarbeiter Arbeitszeiten';
	}

	dateAccessor(field: string, data: any, column: any) {
		// let date = new Date(data[field]);
		// console.log("DATE", new Intl.DateTimeFormat('de-DE').format(date))
		// return new Intl.DateTimeFormat('de-DE').format(date);
	}

	protected readonly roleTypes = roleTypes;
    protected readonly isMobile = isMobile;
}
