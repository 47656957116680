import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TargetWorkingHours } from '../models/target-working-hours.model';
import { TargetWorkingHoursesHttpRequestService } from './target-working-hourses-http-request.service';
import { HttpRequest } from '../../../engine/models/http-request.model';
import { EngineService } from '../../../engine/services/engine.service';
import { TargetWorkingHoursesCacheService } from './target-working-hourses-cache.service';

@Injectable({
  providedIn: 'root'
})
export class TargetWorkingHoursesService {
  private readonly updatedSubject: Subject<void> = new Subject<void>();
  public readonly updated$: Observable<void> = this.updatedSubject.asObservable();

  public constructor(
    private readonly targetworkinghoursesHttpRequestService: TargetWorkingHoursesHttpRequestService,
    private readonly engineService: EngineService,
    private readonly targetworkinghoursesCacheService: TargetWorkingHoursesCacheService
  ) {
    this.engineService.appendProcessorForMany('TargetWorkingHours', this.processMany.bind(this));
    this.engineService.appendProcessorForOne('TargetWorkingHours', this.processOne.bind(this));
  }

  public findAll(): void {
    const request = this.targetworkinghoursesHttpRequestService.findAll();
    this.engineService.execute(new HttpRequest('TargetWorkingHours', 'findAll', request));
  }

  public findOne(id: string): void {
    const request = this.targetworkinghoursesHttpRequestService.findOne(id);
    this.engineService.execute(new HttpRequest('TargetWorkingHours', 'findOne', request));
  }

  public create(targetworkinghours: Partial<TargetWorkingHours>): void {
    const request = this.targetworkinghoursesHttpRequestService.create(targetworkinghours);
    this.engineService.execute(new HttpRequest('TargetWorkingHours', 'create', request));
  }

  public update(targetworkinghours: TargetWorkingHours): void {
    const request = this.targetworkinghoursesHttpRequestService.update(targetworkinghours);
    this.engineService.execute(new HttpRequest('TargetWorkingHours', 'update', request));
  }

  public delete(targetworkinghours: TargetWorkingHours): void {
    const request = this.targetworkinghoursesHttpRequestService.delete(targetworkinghours);
    this.engineService.execute(new HttpRequest('TargetWorkingHours', 'delete', request));
  }

  public processMany(action: string, targetworkinghourses: TargetWorkingHours[]): void {
    switch (action) {
      case 'findAll':
        this.targetworkinghoursesCacheService.targetworkinghourses = targetworkinghourses;
        break;
      case 'findOne':
        console.error('Invalid action', action);
        break;
      case 'create':
        this.targetworkinghoursesCacheService.insertOrUpdateMany(targetworkinghourses);
        break;
      case 'update':
        this.targetworkinghoursesCacheService.insertOrUpdateMany(targetworkinghourses);
        break;
      case 'delete':
        this.targetworkinghoursesCacheService.deleteMany(targetworkinghourses);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }

  public processOne(action: string, targetworkinghours: TargetWorkingHours): void {
    switch (action) {
      case 'findAll':
        console.error('Invalid action', action);
        break;
      case 'findOne':
        this.targetworkinghoursesCacheService.insertOrUpdateOne(targetworkinghours);
        break;
      case 'create':
        this.targetworkinghoursesCacheService.insertOrUpdateOne(targetworkinghours);
        break;
      case 'update':
        this.targetworkinghoursesCacheService.insertOrUpdateOne(targetworkinghours);
        break;
      case 'delete':
        this.targetworkinghoursesCacheService.deleteOne(targetworkinghours);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }


    this.updatedSubject.next();
  }
}
