import { Injectable } from '@angular/core';

enum GermanStates {
	'Baden-Württemberg' = 'BW',
	'Bayern' = 'BY',
	'Berlin' = 'BE',
	'Brandenburg' = 'BB',
	'Bremen' = 'HB',
	'Hamburg' = 'HH',
	'Hessen' = 'HE',
	'Mecklenburg-Vorpommern' = 'MV',
	'Niedersachsen' = 'NI',
	'Nordrhein-Westfalen' = 'NW',
	'Rheinland-Pfalz' = 'RP',
	'Saarland' = 'SL',
	'Sachsen' = 'SN',
	'Sachsen-Anhalt' = 'ST',
	'Schleswig-Holstein' = 'SH',
	'Thüringen' = 'TH'
}

@Injectable({
	providedIn: 'root'
})
export class GermanStateService {
	private stateLongToShort: { [key: string]: GermanStates } = {
		'Baden-Württemberg': GermanStates['Baden-Württemberg'],
		'Bayern': GermanStates.Bayern,
		'Berlin': GermanStates.Berlin,
		'Brandenburg': GermanStates.Brandenburg,
		'Bremen': GermanStates.Bremen,
		'Hamburg': GermanStates.Hamburg,
		'Hessen': GermanStates.Hessen,
		'Mecklenburg-Vorpommern': GermanStates['Mecklenburg-Vorpommern'],
		'Niedersachsen': GermanStates.Niedersachsen,
		'Nordrhein-Westfalen': GermanStates['Nordrhein-Westfalen'],
		'Rheinland-Pfalz': GermanStates['Rheinland-Pfalz'],
		'Saarland': GermanStates.Saarland,
		'Sachsen': GermanStates.Sachsen,
		'Sachsen-Anhalt': GermanStates['Sachsen-Anhalt'],
		'Schleswig-Holstein': GermanStates['Schleswig-Holstein'],
		'Thüringen': GermanStates.Thüringen,
	};

	private stateShortToLong: { [key: string]: string } = Object.keys(this.stateLongToShort).reduce((acc, key) => {
		acc[this.stateLongToShort[key]] = key;
		return acc;
	}, {} as { [key: string]: string });

	getShortForm(state: string): string {
		return this.stateLongToShort[state] || '';
	}

	getLongForm(short: string): string {
		return this.stateShortToLong[short] || '';
	}
}
