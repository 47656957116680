import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Subject } from 'rxjs';

import { DataService } from './data.service';
import { Employee } from '../../entities/employees/models/employee.model';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public selectedUser: Employee;

	public currentUser$: Subject<Employee> = new Subject<Employee>();
	public selectedUser$: Subject<Employee> = new Subject<Employee>();

	public currentEmployee: Employee;
	public selectedEmployee: Employee;

	public constructor(private angularKeycloak: KeycloakService, private dataService: DataService) {
		this.getSelectedEmployee();

		this.dataService.employeesService.updated$.subscribe(async () => {
			await this.updateEmployeeAdminMode();
			await this.updateSelectedEmployee();
		});
	}

	public async getSelectedEmployee() {
		await this.updateEmployeeAdminMode();
		await this.updateSelectedEmployee();
	}

	public async updateEmployeeAdminMode() {
		const currentKeycloakEmployee = await this.getUserKeycloakProfile();

		let currentEmployee = this.dataService.employeesCacheService.employees.find((employee: Employee) => employee.id === currentKeycloakEmployee.id);

		if (currentEmployee) {
			this.currentEmployee = currentEmployee;
			this.currentUser$.next(currentEmployee);
		}
	}

	public async updateSelectedEmployee() {
		if (
			this.selectedUser
			&& this.selectedUser.id
			&& this.dataService.employeesCacheService.employees
			&& this.dataService.employeesCacheService.employees.length > 0
		) {
			let selectedEmployee = this.dataService.employeesCacheService.employees.find((employee: Employee) => employee.id === this.selectedUser.id);

			if (selectedEmployee) {
				this.selectedEmployee = selectedEmployee;
				this.selectedUser$.next(selectedEmployee);
			}
		}
	}

	public isLoggedIn(): boolean {
		return this.angularKeycloak.isLoggedIn();
	}

	public isAdmin(): boolean {
		return this.angularKeycloak.isUserInRole('admin');
	}

	public async getUserKeycloakProfile(): Promise<KeycloakProfile> {
		return await this.angularKeycloak.loadUserProfile();
	}

	public async logout(): Promise<void> {
		await this.angularKeycloak.logout();
	}
}
