import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ConfigService } from '../../../core/services/config.service'
import { Abscence } from '../models/abscence.model'
import { CreateAbscenceDTO } from '../dto/create-abscence.dto'
import { UpdateAbscenceDTO } from '../dto/update-abscence.dto'

@Injectable({
	providedIn: 'root'
})
export class AbscencesHttpRequestService {

	private readonly url: string

	public constructor(private readonly config: ConfigService, private readonly http: HttpClient) {

		this.url = `${this.config.baseUrl}/abscences`
	}

	public findAll(): Observable<Abscence[]> {

		return this.http.get<Abscence[]>(this.url)
	}

	public findOne(id: string): Observable<Abscence> {

		return this.http.get<Abscence>(`${this.url}/${id}`)
	}

	public create(abscence: Partial<Abscence>): Observable<Abscence> {

		const dto: CreateAbscenceDTO = {
			type: abscence.type!,
			from: abscence.from!,
			to: abscence.to!,
			halfDay: abscence.halfDay!,
			substitution: abscence.substitution!,
			comment: abscence.comment!,
			substitutionStatus: abscence.substitutionStatus!,
			employeeId: abscence?.employee?.id ?? undefined,
		}

		return this.http.post<Abscence>(this.url, dto)
	}

	public update(abscence: Abscence): Observable<Abscence> {

		const dto: UpdateAbscenceDTO = {
			type: abscence.type,
			from: abscence.from,
			to: abscence.to,
			halfDay: abscence.halfDay,
			substitution: abscence.substitution,
			comment: abscence.comment,
			substitutionStatus: abscence.substitutionStatus,
			employeeId: abscence?.employee?.id ?? undefined,
		}

		return this.http.put<Abscence>(`${this.url}/${abscence.id}`, dto)
	}

	public delete(abscence: Abscence): Observable<Abscence> {

		return this.http.delete<Abscence>(`${this.url}/${abscence.id}`)
	}

	public getsubstitutionRequest(id: string): Observable<Abscence[]> {

		return this.http.get<Abscence[]>(`${this.url}/get-substitution-request/${id}`)
	}

	public updateSubstitutionStatus(abscence: Abscence): Observable<Abscence> {

		const dto: UpdateAbscenceDTO = {
			type: abscence.type,
			from: abscence.from,
			to: abscence.to,
			halfDay: abscence.halfDay,
			substitution: abscence.substitution,
			comment: abscence.comment,
			substitutionStatus: abscence.substitutionStatus,
			employeeId: abscence?.employee?.id ?? undefined,
		}

		return this.http.put<Abscence>(`${this.url}/update-substitution-request/${abscence.id}`, dto)
	}
}
