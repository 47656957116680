import { Injectable } from '@angular/core';

import { TimeEntry } from '../models/time-entry.model';

@Injectable({
  providedIn: 'root'
})
export class TimeEntriesCacheService {
  public timeentries: TimeEntry[] = [];

  public insertOrUpdateMany(timeentries: TimeEntry[]): void {
    for (const timeentry of timeentries) {
      this.insertOrUpdateOne(timeentry);
    }
  }

  public insertOrUpdateOne(timeentry: TimeEntry): void {
    const index = this.timeentries.findIndex(t => t.id === timeentry.id);

    if (index === -1) {
      this.timeentries.push(timeentry);
    } else {
      this.timeentries[index] = timeentry;
    }
  }

  public deleteMany(timeentries: TimeEntry[]): void {
    for (const timeentry of timeentries) {
      this.deleteOne(timeentry);
    }
  }

  public deleteOne(timeentry: TimeEntry): void {
    const index = this.timeentries.findIndex(t => t.id === timeentry.id);

    if (index === -1) {
      return;
    }

    this.timeentries.splice(index, 1);
  }
}
