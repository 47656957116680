import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'confirmationDialog',
  templateUrl: './confirmationDialog.component.html',
  styleUrls: ['./confirmationDialog.component.scss']
})
export class ConfirmationDialogComponent {

  @Output() closed: EventEmitter<string> = new EventEmitter<string>();
  @Output() send: EventEmitter<string> = new EventEmitter<string>();

  fileName = ""

  close() {

    this.closed.emit('closed')
  }

  onSend() {

    this.send.emit('send')
  }

}
